import React from 'react';
import './newsLetter.css'
import newsletter from '../../assets/newsletter.png'
import Button from '../button/Button';

const NewsLetter = () => {
    return (
        <div className="news-letter w-full">
            <div className="news-letter-container flex flex-col flex-wrap content-center justify-center p-4 py-20 mx-auto md:p-10">
                <h1 className="text-5xl antialiased font-semibold leading-none text-center">
                    Want to know when we do more?
                </h1>

                <p className="parag1 pt-2 pb-8 antialiased text-center mt-10">
                    Get product updates, launch info, and special deals <br /> by signing up for my newsletter.
                </p>
                {/* <div className="flex flex-row"> */}
                    {/* <input type="email"
                        placeholder="Enter your email"
                        className="px-3" />
                    <Button type="submit" text="Sign Up" /> */}


                    <form action="https://finclancapital.us14.list-manage.com/subscribe/post?u=230c9156bebe1d2c8046ee705&amp;id=8de6f78bd8&amp;f_id=00d5a7e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="flex flex-row" target="_blank">

                        <input
                            className="px-3"
                            type="email"
                            name="EMAIL"
                            placeholder="Enter your email"
                            required=""
                        />
                        <input
                            type="submit"
                            name="subscribe"
                            value="SignUp"
                        />


                    </form>


                {/* </div> */}
                <p className='text-center mt-2'>You're signing up to receive emails from Finclan.</p>
            </div>

            {/* <img src={newsletter} alt="" /> */}

        </div>
    );
};

export default NewsLetter;
