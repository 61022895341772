import React, { useEffect, useState } from "react";
import './testimonials.css'
// import accountImg from "../../assets/1f7bd360307b00b4157a9a98e1533ac7.png"
import accountImg from "../../assets/img1.jpeg";


const Testimonials = () => {
    const blogPosts = [
        {
            description: "The best clan I can recommend will have to be this. The clan members are welcoming. The strategies are top-notch and proven.",
            imageUrl:
                "https://images.unsplash.com/photo-1613442368724-5bea257f8cbd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80",
            authorImg: "https://images.unsplash.com/photo-1519689373023-dd07c7988603?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
            authorName: "Raphael Ben",
            authorLocation: "Manchester, UK",
        },
        {
            description: "I have never been a person of building with exclusive take-ons. More than excited I joined this space and building for the future with a clan.",
            imageUrl:
                "https://images.unsplash.com/photo-1614029655965-2464911905a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80",
            authorImg: "https://images.unsplash.com/photo-1563721572772-fbf713fff374?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
            authorName: "Adekunle Bimbo",
            authorLocation: "Lagos, Nigeria",
        },
        {
            description: "I joined Finclan when I had almost nothing. I secured time to invest in strategies and signals on trades there. I am living well today. It is a clan for you.",
            imageUrl:
                "https://images.unsplash.com/photo-1583752028088-91e3e9880b46?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
            authorImg: accountImg,
            authorName: "Miracle Twokala",
            authorLocation: "Cape Town, SA",
        },

    ];

    const [counter, setCounter] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (counter === 80) {
                clearInterval(interval);
            } else {
                setCounter(counter + 1);
            }
        }, 10);
        return () => clearInterval(interval);
    }, [counter]);
    return (

        <div className="py-12 testimonial">
            <div className="">
                <div className="mb-12 space-y-2 text-center">
                    <h2 className="text-3xl font-bold md:text-4xl">
                        Clan Testimonials
                    </h2>
                </div>
                <div className="testimonial-container items-center gap-6">
                    <div
                        className="flex flex-col gap-4 progress items-center testimonial-box group px-4 rounded-3xl bg-white border border-gray-100 "
                    >
                        <p className="text-center">With a 80% win rate our clan members do well</p>
                        <div className="percent">
                            <svg>
                                <circle cx="70" cy="70" r="70" ></circle>
                                <circle cx="70" cy="70" r="70" ></circle>
                            </svg>
                            <div className="num">
                                <h2>80<span>%</span></h2>
                            </div>
                        </div>
                    </div>
                    {blogPosts.map((post, index) => (
                        <div
                            key={index}
                            className="testimonial-box group p-4 sm:p-6 rounded-3xl bg-white border border-gray-100 "
                        >
                            <div className="relative overflow-hidden rounded-xl">
                                <img
                                    src={post.imageUrl}
                                    alt="art cover"
                                    loading="lazy"
                                    width="1000"
                                    // height= "131"
                                    className="h-32 w-full object-cover"
                                />

                            </div>
                            <div className="mt-6">
                                <div className="mt-4 flex justify-between items-center">
                                    <div className="flex items-center">
                                        <img
                                            src={post.authorImg}
                                            alt="avatar"
                                            className="h-10 w-10 rounded-full object-cover mr-2"
                                        />
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <span className="text-gray-700 dark:text-gray-300 text-sm">
                                                <h5>{post.authorName}</h5>
                                            </span>
                                            <span className="text-gray-700 dark:text-gray-300 text-sm">
                                                <h6>{post.authorLocation}</h6>
                                            </span>
                                        </div>
                                    </div>
                                    <span className=" text-sm">
                                        {post.date}
                                    </span>
                                </div>
                                {/* <h3 className="text-lg font-semibold">
                                    <a
                                        href={post.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-gray-900 dark:text-gray-100 hover:underline"
                                    >
                                        {post.title}
                                    </a>
                                </h3> */}
                                <p className="mt-2 ">
                                    {post.description}
                                </p>

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Testimonials;
