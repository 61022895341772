import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './pricing.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ico1 from '../../assets/ico1.png';
import ico2 from '../../assets/ico2.png';
import ico3 from '../../assets/ico3.png';
import Button from '../button/Button';

const Pricing = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 670);

    const pricingPlans = [
        {
            title: 'Community',
            price: '$150/year',
            img: ico1,
            description: [
                'Network with Clan Members',
                'Weekly Meetups',
                'Trading News & Resources',
                'Signals and Projections',
                'Market Analysis',
                'Market Structures',
                '',
                '',
                '',
            ],
        },
        {
            title: 'Premium',
            price: '$1,000/year',
            img: ico2,
            description: [
                'Network with Clan Members',
                'Daily Meetups',
                'Trading News & Resources',
                'Signals and Projections',
                'NFP and Market Analysis',
                'One-on-One Mentorship',
                'Market Structures',
                'Advanced Clan Courses',
                '',
            ],
        },
        {
            title: 'For Life',
            price: '$10,000',
            img: ico3,
            description: [
                'Network with Clan Members',
                'Daily Meetups',
                'Trading News & Resources',
                'Signals and Projections',
                'NFP and Market Analysis',
                'One-on-One Mentorship',
                'Market Structures',
                'Advanced Clan Courses',
                'For Life Membership Access',
            ],
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 670,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
        customPaging: (i, currentSlide) => (
        <div className={`custom-dot ${currentSlide === i ? 'active' : ''}`}>
            {i + 1}
        </div>
    ),
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 670);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="pricing-container">
            <h1 className='pricing-heading'>Pricing</h1>

            <div className="pricing-box">
                {isMobile ? (
                    <Slider {...settings}>
                        {pricingPlans.map((plan, index) => (
                            <div className="pricing-plan" key={index}>
                                <div className='plan-head'>
                                    <div className='plan-head-details'>
                                        <p>{plan.title}</p>
                                        <h4 className='big'>{plan.price}</h4>
                                    </div>
                                    <div className='layout-img' style={{ background: `url(${plan.img})`, backgroundSize: "contain", backgroundPositionX: "100%", backgroundPositionY: "0", backgroundRepeat: "no-repeat" }}></div>
                                </div>
                                <ul>
                                    {plan.description.map((item, itemIndex) => (
                                        <li key={itemIndex} className={item.trim() === '' ? 'empty-list-item' : ''}>{item}</li>
                                    ))}
                                </ul>
                                <Button text="Purchase Now" link="/signup" />
                            </div>
                        ))}
                    </Slider>
                ) : (
                    pricingPlans.map((plan, index) => (
                        <div className="pricing-plan" key={index}>
                            <div className='plan-head'>
                                <div className='plan-head-details'>
                                    <p>{plan.title}</p>
                                    <h4 className='big'>{plan.price}</h4>
                                </div>
                                <div className='layout-img' style={{ background: `url(${plan.img})`, backgroundSize: "contain", backgroundPositionX: "100%", backgroundPositionY: "0", backgroundRepeat: "no-repeat" }}></div>
                            </div>
                            <ul>
                                {plan.description.map((item, itemIndex) => (
                                    <li key={itemIndex} className={item.trim() === '' ? 'empty-list-item' : ''}>{item}</li>
                                ))}
                            </ul>
                            <Button text="Purchase Now" link="/signup" />
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default Pricing;
