import React, { useState, useEffect } from 'react';
import SearchOutput from './SearchOutput';
import { FaSearch } from 'react-icons/fa';
import './bankResult.css';

const BankResult = ({ results, setResults, result }) => {
    const [input, setInput] = useState('');
    const [selectedResult, setSelectedResult] = useState('');
    const [selectedBankCode, setSelectedBankCode] = useState(''); // State to store the selected bank code

    const fetchData = (value) => {
        fetch('https://finclan-api-wunt.onrender.com/payment/bank_list')
            .then((response) => response.json())
            .then((json) => {
                const results = json.banks.data.filter((bank) => {
                    return (
                        value &&
                        bank &&
                        bank.name &&
                        bank.name.toLowerCase().includes(value.toLowerCase())
                    );
                });
                setResults(results);

            });
    };

    const handleResultClick = (result) => {
        setInput(result); // Set the clicked result in the input field
        setSelectedResult(result);
        localStorage.setItem('bankName', result)
        // console.log(result)

        // Find the selected bank's code in the API data
        const selectedBank = results.find((bank) => bank.name === result);

        if (selectedBank) {
            setSelectedBankCode(selectedBank.code); // Set the selected bank code in state
            // Save the selected bank code to localStorage
            localStorage.setItem('selectedBankCode', selectedBank.code);
            console.log(selectedBank.code)

        }

        // Clear the search results
        setResults([]);
    };

    const handleChange = (value) => {
        setInput(value);
        fetchData(value);
    };

    // Function to clear relevant items from localStorage
    const clearLocalStorage = () => {
        localStorage.removeItem('selectedBankCode'); // Clear the selectedBankCode
        // localStorage.removeItem(bankCode);
        // Add other relevant items you want to clear here
    };

    // Add an event listener for beforeunload to clear localStorage
    useEffect(() => {
        window.addEventListener('beforeunload', clearLocalStorage);

        return () => {
            // Remove the event listener when the component unmounts
            window.removeEventListener('beforeunload', clearLocalStorage);
        };
    }, []);



    return (
        <div className='bank-result-container'>
            <div className='input-label'>
                <input
                    type='text'
                    placeholder='Type to search...'
                    value={input}
                    onChange={(e) => handleChange(e.target.value)}
                />
            </div>

            <div className='results-control'>
                <div className='results-list'>
                    {results.map((result, id) => {
                        return (
                            <SearchOutput
                                onResultClick={handleResultClick}
                                className={'each-option'}
                                result={result.name}
                                key={id}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default BankResult;
