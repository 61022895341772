import React from 'react'
import PageBanner from '../../components/pageBanner/PageBanner'
import Mentor from '../../containers/mentor/Mentor'
import Testimonials from '../../containers/testimonials/Testimonials'
import NewsLetter from '../../components/newsLetter/NewsLetter'

const Mentorship = () => {
    return (
        <>
            <PageBanner heading={"Mentorship"} />
            <Mentor />
            <Testimonials/>
            <NewsLetter/>
        </>
    )
}

export default Mentorship
