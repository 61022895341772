import React, { useState } from 'react';
import './resetPassword.css';
import Button from './../button/Button';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [generatedCode, setGeneratedCode] = useState(null);
    const [userEnteredCode, setUserEnteredCode] = useState(''); // State for user-entered code
    const [showCodeInput, setShowCodeInput] = useState(false); // State to show the code input
    const [codesMatch, setCodesMatch] = useState(false); // State to track code match


    // Function to generate a random 6-digit code
    const generateRandomCode = () => {
        const code = Math.floor(100000 + Math.random() * 900000);
        setGeneratedCode(code);

        // Send the email and generated code to the API
        // sendCodeToAPI(email, code);
        // Create a data object to send to the API
        const data = {
            email,
            code,
        };

        // Replace 'your_api_endpoint' with your actual API endpoint
        fetch('https://finclan-api-wunt.onrender.com/user/reset_password_email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), // Use the data object here
        })
            .then((response) => {
                // Check if the API request was successful
                if (response.ok) {
                    response.json().then(data => {
                        console.log('API Response:', data);
                    });
                    setShowCodeInput(true); // Show the code input
                } else {
                    response.json().then(data => {
                        console.error('API Error:', data);
                    });
                    // Handle API request error
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
                // Handle errors here
            });
    };


    // Function to send the email and generated code to the API
    const sendCodeToAPI = () => {
        if (userEnteredCode == generatedCode) {
            // Codes match, proceed with sending the data to the API
            const data = {
                email,
                code: generatedCode,
            };

            // Replace 'your_api_endpoint' with your actual API endpoint
            fetch('https://finclan-api-wunt.onrender.com/user/reset_password_email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('API Error');
                }
            })
            .then((data) => {
                console.log('API Response:', data);
                localStorage.setItem('passEmail', email);
                setCodesMatch(true);
                openNewPage();
            })
            .catch((error) => {
                console.error('API Error:', error.message);
                // Handle errors here
            });
        } else {
            // Codes do not match, show an alert
            alert('Wrong code entered. Please try again.');
        }
    };


    // Function to open a new page
    const openNewPage = () => {
        window.location.href = '/Change-password'; // Replace with your desired new page URL
    };

    return (
        <div className="affiliate-conatiner">
            <div className="password-box">
                <h2>Reset Password.</h2>
                <div className="input-container">
                    <p className="label_ pl-2">Email</p>
                    <div className="field input-field">
                        <input
                            type="text"
                            placeholder="Enter your Email"
                            className="input"
                            autoComplete="username"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <a href="#/" onClick={generateRandomCode}>
                        Generate Code
                    </a>
                </div>

                {showCodeInput && (
                    <>
                        <div className="input-container">
                            <p className="label_ pl-2">Code</p>
                            <div className="field input-field">
                                <input
                                    type="text"
                                    placeholder="Enter 6 digit code"
                                    className="input"
                                    autoComplete="off"
                                    value={userEnteredCode}
                                    onChange={(e) => setUserEnteredCode(e.target.value)}
                                />
                            </div>
                        </div>
                        <input className="pass-submit" type="submit" onClick={sendCodeToAPI} />
                    </>
                )}
            </div>
        </div>
    );
};

export default ResetPassword;
