import React, { useState } from 'react';
import usd from '../../assets/usd.png';
import crypt from '../../assets/cryp.png';
import './payOption.css';
import PopUp from '../popUp/PopUp';

const PayOption = ({ email, updatePaymentStatus, selectedOption, selectedPrice }) => {
    const [isActive, setIsActive] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [autoClickButton, setAutoClickButton] = useState(false);

    const options = [
        {
            icon: usd,
            text: 'Naira',
        },
        {
            icon: crypt,
            text: 'Crypto USDT',
        },
    ];

    // Format the price with commas only if selectedPrice is not null or undefined
    const formattedPrice = selectedPrice ? selectedPrice.toLocaleString('en-US') : null;

    const handleOptionClick = (optionText) => {
        if (optionText === 'Finclan Affilate' || optionText === 'Naira') {
            setTimeout(() => {
                setShowModal(true);
                // Set autoClickButton to true after a delay of 3 seconds
                setTimeout(() => {
                    setAutoClickButton(true);
                }, 3000);
            }, 1500);
        } else {
            setTimeout(() => {
                setTimeout(() => {
                    window.location.href = '/usdtpay';
                }, 0);
            }, 0);
        }

        // setIsActive(false);
    };

    return (
        <>
            <div className="form mb-10">
                <div className="form-content">
                    <h2>Payment</h2>
                    <p>Selected Price: {formattedPrice ? `₦${formattedPrice}` : 'N/A'}</p>
                    <div className={`select-menu ${isActive ? 'active' : ''}`}>
                        <div className="select-btn" onClick={() => setIsActive(!isActive)}>
                            <span className="sBtn-text">Select payment method</span> {/*{selectedOption}*/}
                            <i className="bx bx-chevron-down"></i>
                        </div>
                        <ul className="options">
                            {options.map((option, index) => (
                                <li className="option" key={index} onClick={() => handleOptionClick(option.text)}>
                                    <span className="option-text">{option.text}</span>
                                    <img src={option.icon} alt="" />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {showModal && (
                <PopUp updatePaymentStatus={updatePaymentStatus} email={email} price={selectedPrice} popupType="signup" autoClickButton={autoClickButton} />
            )}
        </>
    );
};

export default PayOption;
