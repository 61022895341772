import React, { useState, useEffect } from 'react';
import Account from '../../components/account/Account';
import Billing from '../../components/billing/Billing';
import './profile.css';
import Alert from './../../components/alert/Alert';
import LoginForm from './../../components/form/LoginForm';
import { useAuth } from '../../hooks/useAuth/AuthContext';
import { useLocation, useHistory } from 'react-router-dom';


const Profile = () => {
    const [showLoginSuccessAlert, setShowLoginSuccessAlert] = useState(false);

    // // Use useEffect to set showLoginSuccessAlert to true when the component mounts
    // useEffect(() => {
    //     setShowLoginSuccessAlert(false);
    // }, []);

    // // Callback function to handle login success
    // const handleLoginSuccess = () => {
    //     setShowLoginSuccessAlert(true);
    // };

    const history = useHistory();
    const { user, logout } = useAuth();

    const [isRedirecting, setIsRedirecting] = useState(false);

    useEffect(() => {
        if (!user) {
            const authToken = localStorage.getItem('authToken');
            const profileAccess = localStorage.getItem('userRole');
            if (!authToken || profileAccess === 'non_member') {
                setIsRedirecting(true);
                console.log('not a user')
                history.push('/signup');
            }
        }
    }, [user, history]);
    // useEffect(() => {
    //     if (!user) {
    //         const authToken = localStorage.getItem('authToken');
    //         if (!authToken) {
    //             history.push('/signup');
    //         }
    //     }
    // }, [user, history]);

    if (isRedirecting) {
        history.push('/signup');
        //  Return null to prevent rendering the rest of the component
        return null;
    }


    return (
        <>
            <div className='profile-container'>
                <Account />
                <Billing />

            </div>

            {showLoginSuccessAlert && (
                <Alert type="success" message="Login successful" />
            )}
        </>
    );
}

export default Profile;
