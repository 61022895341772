import React, { useState, useEffect } from 'react';
import './details.css';
import ico1 from '../../assets/ico1.png';
import ico2 from '../../assets/ico2.png';
import ico3 from '../../assets/ico3.png';
import Withdraw from '../withdraw/Withdraw';
import WithdrawDetails from './../withdrawDetails/WithdrawDetails';

const Details = () => {
    const [showWithdrawPopup, setShowWithdrawPopup] = useState(false);
    const [showFirstPopup, setShowFirstPopup] = useState(true);
    const [showSecondPopup, setShowSecondPopup] = useState(false);
    const [showActive, setShowActive] = useState('');
    const [balance, setBalance] = useState(0);
    const [referrals, setReferrals] = useState(0);
    const [withdrawals, setWithdrawals] = useState(0);
    const [totalBalance, setTotalBalance] = useState(0);
    const [error, setError] = useState(""); // Define the error state
    const [amount, setAmount] = useState(""); // Add the amount state

    const Item = [
        {
            title: 'Balance',
            description: `₦${balance}`,
            img: ico3,
            total: `Total: ₦${totalBalance}`,
        },
        {
            title: 'Referrals',
            description: referrals,
            img: ico2,
            total: `Total: ₦${totalBalance}`,
        },
        {
            title: 'Withdrawals',
            description: withdrawals,
            img: ico1,
            button: 'Withdraw',
        },
    ];

    useEffect(() => {
        // Fetch data from the API when the component mounts
        async function fetchData() {
            try {
                const authToken = localStorage.getItem('authToken');
                const apiUrl = 'https://finclan-api-wunt.onrender.com/user/profile'; // Replace with your API URL

                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: authToken }),
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                    setBalance(data.profile.balance);
                    setReferrals(data.profile.no_referrals);
                    setWithdrawals(data.withdrawals.length);
                    setTotalBalance(data.profile.total_balance);

                    // Log the fetched content
                    console.log('Fetched Data:', data);
                } else {
                    console.error('Failed to fetch data from API');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData(); // Call the fetchData function when the component mounts
    }, []); // The empty dependency array ensures this effect runs only once

    // Function to handle the "bx-arrow-back" click
    const handleBackClick = () => {
        localStorage.removeItem('selectedWithdrawalOption'); // Remove selected option from localStorage
        setShowWithdrawPopup(false); // Close the popup
        setShowSecondPopup(false);
        setShowFirstPopup(true);
    };

    const handleContinue = () => {
        if (isNaN(amount)) {
            setError("Please enter a valid numeric amount.");
        } else if (parseFloat(amount) > balance) {
            setError("Withdrawal amount exceeds your balance.");
        } else {
            localStorage.removeItem('selectedBankCode');
            localStorage.removeItem('bankName');
            localStorage.removeItem('affiliateAccountNumber');
            setShowWithdrawPopup(false);
            setShowSecondPopup(true);
            setError(""); // Clear any previous error message
        }


    };

    useEffect(() => {
        if (error) {
            console.log(error);
        }
    }, [error]);

    const handleSecondBackClick = () => {
        setShowWithdrawPopup(true);
        setShowSecondPopup(false);
    };

    return (
        <>
            <div className='details-container'>
                <h1 className='details-heading'>Finclan Affiliate</h1>
                <div className='details-box'>
                    {Item.map((value, key) => (
                        <div key={key} className="layout">
                            <div className='layout-gradient'></div>
                            <div className="layout-details">
                                <div className="layout-details-box">
                                    <p>{value.title}</p>
                                    <h4 className="big">{value.description}</h4>
                                </div>
                                <div className='layout-img' style={{ background: `url(${value.img})`, backgroundSize: "contain", backgroundPositionX: "100%", backgroundPositionY: "0", backgroundRepeat: "no-repeat" }}></div>
                            </div>
                            <div className='layout-total'>
                                <p>{value.total}</p>
                                {value.button && (
                                    <button
                                        className='layout-button'
                                        onClick={() => {
                                            setShowWithdrawPopup(true);
                                        }}
                                    >
                                        {value.button}
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* {showWithdrawPopup && (
                <div className={`popup-container dashboard-popup-container`}>
                    <Withdraw continueAction={handleContinue} clickAction={handleBackClick} />
                </div>
            )}
            {showSecondPopup && (
                <div className={`popup-container dashboard-popup-container`}>
                    <WithdrawDetails clickAction={handleSecondBackClick} />
                </div>
            )} */}
            {showWithdrawPopup && (
                <div className={`popup-container dashboard-popup-container`}>
                    <Withdraw
                        continueAction={handleContinue}
                        clickAction={handleBackClick}
                        setWithdrawAmount={setAmount}
                        amount={amount}
                        error={error} // Pass the error message as a prop
                    />
                </div>
            )}
            {showSecondPopup && (
                <div className={`popup-container dashboard-popup-container`}>
                    <WithdrawDetails
                        clickAction={handleSecondBackClick}
                        amount={amount} // Pass the amount to the second popup
                    />
                </div>
            )}
        </>
    );
}

export default Details;
