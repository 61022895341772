import React, { useState } from "react";
import './courseList.css';
import PopUp from './../../components/popUp/PopUp';
import SuccessModal from './../../components/modal/SuccesModal';
import FailedModal from "../../components/modal/FailedModal";

function CourseList() {
    const [showEmailPopup, setShowEmailPopup] = useState(false);
    const [email, setEmail] = useState(""); // State for email input
    const [showModal, setShowModal] = useState(false);
    const [showFailedModal, setFailedShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [autoClickButton, setAutoClickButton] = useState(false); // State to trigger auto-click
    const [price, setPrice] = useState(""); // State for course price input
    const [selectedItem, setSelectedItem] = useState(null); // State to store the selected item
    const [postData, setPostData] = useState(null); // State to store the payment data

    const [paymentStatus, setPaymentStatus] = useState(localStorage.getItem('Payment Successful'));

    const items = [
        {
            course: "course_1",
            id: "$200",
            price: 200000,
            imgSrc: "https://images.unsplash.com/photo-1661956602868-6ae368943878?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
            title: "Many begin but only selected few reign",
            description:
                "Discover the secrets of Forex trading with our comprehensive course. Join us and unlock the potential of the global financial markets. Learn the fundamentals of Forex from understanding currency pairs to analyzing market trends. Master the art of technical analysis and develop effective trading strategies. Gain the confidence to navigate the Forex market and start trading now.",
            buttonText: "Get Course",
        },
        {
            id: "$400",
            course: "course_2",
            price: 400000,
            imgSrc: "https://images.unsplash.com/photo-1579621970795-87facc2f976d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
            title: "Value your financial growth exclusively",
            description:
                "Unlock the secrets of currency trading with our comprehensive course. Dive deep into the world of forex and gain a clear understanding of the dynamics that drive currency markets. Learn how to analyze market trends, interpret charts, and make informed trading decisions. With expert guidance, you'll explore various trading strategies and risk management techniques.",
            buttonText: "Get Course",
        },
    ];

    const handlePurchaseClick = (item) => {
        setSelectedItem(item);
        setShowEmailPopup(true);
        localStorage.clear();
    };

    const handleBackClick = () => {
        setShowEmailPopup(false);
    };

    // Define a function to update payment status and set it in localStorage
    function updatePaymentStatus(status) {
        setPaymentStatus(status);
        // console.log('status', status);
        localStorage.setItem('courseReference', status);

        // Call a function to handle the change immediately
        handleStorageChange({ key: 'courseReference', newValue: status });
    }

    // Function to handle changes in localStorage
    function handleStorageChange(event) {
        // console.log('handleStorageChange()1');

        if (event.key === 'courseReference' && event.newValue >= 10) {
            // console.log('handleStorageChange()2');
            setShowSuccessModal(true)

        }

    }

    const handlePurchase = () => {
        // console.log(email);
        // console.log(price);

        const courseReference = localStorage.getItem('courseReference'); // Corrected variable name

        setShowEmailPopup(false);
        setTimeout(() => {
            setShowModal(true);
            setTimeout(() => {
                setAutoClickButton(true);

                if (courseReference !== null) { // Check if courseReference is not null
                    // Simulate a successful payment
                    const paymentStatus = "success";
                    if (paymentStatus === "success") {
                        // Create the payment data
                        const paymentData = {
                            reference: courseReference,
                            course: selectedItem.course,
                            email,
                        };

                        // Set the payment data in state
                        setPostData(paymentData);

                    }
                }
            }, 3000);
        }, 1500);
    };

    return (
        <section className="p-4 lg:p-8 dark:text-gray-100">
            <div className="course-container mx-auto space-y-12 px-28">
                {items.map((item) => (
                    <div
                        key={item.id}
                        className="course-card overflow-hidden rounded-2xl"
                    >
                        <div className="course-img" style={{ background: `url(${item.imgSrc})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                        </div>
                        <div className="flex flex-col justify-center flex-1 p-8">
                            <span className="text-xs uppercase">
                                {item.id}
                            </span>
                            <h3 className="course-title mt-3">{item.title}</h3>
                            <p className="course-detatils mb-6 mt-2">{item.description}</p>
                            <button onClick={() => handlePurchaseClick(item)}>{item.buttonText}</button>
                        </div>
                    </div>
                ))}
            </div>

            {showEmailPopup && (
                <div className='popup-container'>
                    <div className='popup popup-p input-label'>
                        <i className="bx bx-arrow-back" onClick={handleBackClick}></i>
                        <input
                            type="text"
                            value={email}
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)} // Update the email state
                        />
                        <button className="buy-button" onClick={handlePurchase}>Purchase</button>
                    </div>
                </div>
            )}

            {showSuccessModal && (
                <SuccessModal email={email} onClose={() => setShowSuccessModal(false)} />
            )}

            {showFailedModal && (
                <FailedModal onClose={() => setFailedShowModal(false)} />
            )}

            {showModal && selectedItem && (
                <PopUp email={email} price={selectedItem.price} popupType="courselist" autoClickButton={autoClickButton} course={selectedItem.course} updatePaymentStatus={updatePaymentStatus} />
            )}
        </section>
    );
}

export default CourseList;
