import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './usdtPayment.css';
import cryp2 from '../../assets/cryp2.png';
import barCode from '../../assets/barCode.png';
import { MdOutlineContentCopy, MdCheck } from 'react-icons/md';
import Article from '../article/Article';

const UsdtPayment = () => {
    const [copied, setCopied] = useState(false);
    const [paymentReceived, setPaymentReceived] = useState(false);
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const history = useHistory();

    const confirmLinks = [
        { name: 'Cancel', link: '/', onClick: () => setShowCancelPopup(true) },
        { name: 'I Have Paid', link: '/Course', onClick: () => { setPaymentReceived(true);  setTimeout(() => {
            window.location.href = '/profile';
        }, 3000);} },
    ];

    const handleCopy = () => {
        navigator.clipboard.writeText('bc1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh');
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };


    const address = window.innerWidth < 768 ? 'bc1qxy2kgdygjrsqtzq...' : 'bc1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh';

    return (
        <>
            <div className='usdt-container'>
                <div className='usdt-box'>
                    <img className='img1' src={cryp2} alt='' />
                    <img className='img2' src={barCode} alt='' />
                    <button className='mb-6 mt-4' onClick={handleCopy}>
                        {copied ? 'Copied!' : address}
                        {copied ? <MdCheck /> : <MdOutlineContentCopy />}
                    </button>
                    <Article className='usdt-warning' heading='Send only USDT to this address' details='Do not send any other currency to this address, otherwise you will lose funds' />
                    <div className='usdt-link mt-12'>
                        {confirmLinks.map((link) => (
                            <button className='text-gray-800 hover:text-gray-400 duration-500' onClick={link.onClick}>
                                {link.name}
                            </button>
                        ))}
                    </div>
                    {paymentReceived && (
                        <div className={`alert-box ${paymentReceived ? 'show' : ''}`}>
                            Payment Received!
                        </div>
                    )}
                    {showCancelPopup && (
                        <div className='popup-container'>
                            <div className='popup'>
                                <p>Are you sure you want to cancel your transaction? you will not have access to all our member features if you cancel.</p>
                                <div className='popup-buttons'>
                                    <button onClick={() => { history.push('/signup'); }}>Ok</button>
                                    <button onClick={() => setShowCancelPopup(false)}>Go back</button>
                                </div>
                            </div>
                            <div className='page-overlay'></div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default UsdtPayment;
