import React from "react";
import Button from "../../components/button/Button";
import { Link } from 'react-router-dom';
import './united.css'
import { AiFillYoutube } from "react-icons/ai";
import ytImg from "../../assets/img2.jpeg";
import video from "../../assets/finVid.mp4";


const items = [
    {
        id: "Start Fee -  $10,000",
        imgSrc: ytImg,
        title: "Lifetime coaching and mentoring in your finance journey",
        description:
            "Building with the clan is strength and everything you need to transform your finance. Coaching and mentorship sessions are added value to leverage from the clan. As a premium package, you can develop better with focused details on your trades. There are life-long available perks for your use as a premium clan member. You get to join other clan members on this package to enjoy the following: Trade Entry Methods, Market Formation, Psychology and Ideology in Forex Trading, Consistency Criteria Rule and much more.",
        buttonText: "Comming Soon",
    },
];

function United() {
    return (
        <section className="united-container px-4 py-6 lg:p-16 dark:text-gray-100">
            <h2 className="text-center mb-2">United We Stand</h2>
            <div className="mx-auto space-y-12 px-15">
                {items.map((item) => (
                    <div
                        key={item.id}
                        className="united-card flex-row overflow-hidden rounded-2xl"
                    >
                        <div className="p-2 united-img-container">
                            {/* <Link to='https://www.youtube.com/watch?v=z7538iNe2Pw'>
                                <div className="united-img" style={{ background: `url(${item.imgSrc})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                                    <h2>
                                        <AiFillYoutube />
                                    </h2>
                                </div>
                            </Link> */}
                            <video className="united-img" controls muted>
                                <source src={video} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>

                            {/* <video controls>
                                <source src="./assets/images/-e6c9-493a-a5d2-548bbfe08ed3.mp4" type="video/mp4">
                            </video> */}
                        </div>

                        <div className="united-text flex flex-col justify-center flex-1 p-8">
                            <span className="text-xs uppercase">
                                {item.id}
                            </span>
                            <h3 className="course-title mt-3">{item.title}</h3>
                            <p className="course-detatils mb-6 mt-2">{item.description}</p>
                            <a href="mailto:finclancapital@gmail.com" target="_blank" rel="noreferrer noopener">
                                <button>Get Package</button>
                            </a>
                            {/* <Button to="mailto:finclancapital@gmail.com" text="Get Package" /> */}

                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default United;




