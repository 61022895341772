import React from 'react'
import './successModal.css'

function FailedModal({ onClose }) {
    return (
        <div className="approved-modal modal-overlay">
            <div className="modal">
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                <h3 className='modal-error'>Failed!</h3>
                <p>Something went wrong</p>
            </div>
        </div>
    );
}

export default FailedModal;