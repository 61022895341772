import React, { useState, useEffect } from 'react';
import './billing.css';
import BankResult from './../bankResult/BankResult';

const Billing = () => {
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [editedDetails, setEditedDetails] = useState('');
    const [editedDetails2, setEditedDetails2] = useState('');
    const [editedIndex, setEditedIndex] = useState(-1);
    const [results, setResults] = useState([]);
    const [affiliateAccountNumber, setAffiliateAccountNumber] = useState(localStorage.getItem('affiliateAccountNumber') || '1234567890');
    const [bankName, setBankName] = useState(localStorage.getItem('bankName') || 'UBA');

    useEffect(() => {
        // Fetch account number and bank name from the API here
        const fetchBillingDetails = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const billingUrl = 'https://finclan-api-wunt.onrender.com/user/profile';

                const response = await fetch(billingUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "token": authToken
                    })
                });

                if (response.ok) {
                    const billingData = await response.json();
                    const billingInfo = billingData.billing[0]; // Assuming there's only one billing record
                    setAffiliateAccountNumber(billingInfo.account_number);
                    setBankName(billingInfo.account_bank_name);
                } else {
                    console.error('Failed to fetch Billing information from API');
                }
            } catch (error) {
                console.error('Error fetching Billing information from API:', error);
            }
        };

        fetchBillingDetails(); // Call the function to fetch data
    }, []); // Empty dependency array to run this effect only once, similar to componentDidMount

    const handleEditClick = (index) => {
        if (index === 2) {
            // Show the edit popup for bank account details
            setEditedIndex(index);
            setEditedDetails(affiliateAccountNumber);
            setEditedDetails2(bankName);
        } else {
            // Handle other fields (Community, Payment Method, etc.) as before
        }
        setShowEditPopup(true);
    };

    const handleSaveClick = async () => {
        setShowEditPopup(false);

        if (editedIndex === 2) {
            try {
                const authToken = localStorage.getItem('authToken');
                const bankCode = localStorage.getItem('selectedBankCode');
                const bankName = localStorage.getItem('bankName');
                const updateBillingUrl = 'https://finclan-api-wunt.onrender.com/user/update/billing';

                const response = await fetch(updateBillingUrl, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        account_number: editedDetails,
                        account_bank_name: bankName,
                        account_bank: bankCode,
                        token: authToken,
                    }),
                });


                // Log the response status and status text
                console.log('Response Status:', response.status);
                console.log('Response Status Text:', response.statusText);

                if (response.ok) {
                    // Log the response data if it's in JSON format

                    try {
                        const responseData = await response.json();
                        console.log('Response Data:', responseData);
                    } catch (error) {
                        console.error('Error parsing JSON response data:', error);
                    }

                    console.log('Billing information updated successfully in API');

                    // Update the edited bank name (editedDetails2) with the new value
                    setEditedDetails2(bankName);

                    // Update the state and local storage with the new bank name
                    setAffiliateAccountNumber(editedDetails);
                    setBankName(bankName); // Update bankName with the new value
                    localStorage.setItem('affiliateAccountNumber', editedDetails);
                    localStorage.setItem('bankName', bankName); // Update localStorage with the new bank name
                } else {
                    console.error('Failed to update Billing information in API');
                }
            } catch (error) {
                console.error('Error updating Billing information in API:', error);
            }
        }
        // Handle other cases for different fields as needed
    };


    const handleDiscardClick = () => {
        setShowEditPopup(false);
        setEditedDetails('');
        setEditedDetails2('');
        setEditedIndex(-1);
    };

    console.log(results)

    return (
        <>
            <div className='account-container billing-container'>
                <div className='account-box'>
                    <h3 className='big'>Billing</h3>
                    <div className='account-details'>
                        {/* Display Community and Payment Method */}
                        <div className='details billing-unClick'>
                            <div className='details-title'>
                                <p className=''>Community</p>
                            </div>
                            <div className='details-info'>
                                <p>Finance</p>
                                <button id={`edit-button-0`} >
                                    Edit
                                </button>
                            </div>
                        </div>
                        <div className='details billing-unClick'>
                            <div className='details-title'>
                                <p className=''>Payment Method</p>
                            </div>
                            <div className='details-info'>
                                <p>Paystack</p>
                                <button id={`edit-button-1`}>
                                    Edit
                                </button>
                            </div>
                        </div>

                        {/* Display Bank Account details */}
                        <div className='details billing-unClick'>
                            <div className='details-title'>
                                <p className=''>Affiliate Account Number</p>
                            </div>
                            <div className='details-info'>
                                <p>{affiliateAccountNumber} {bankName}</p>
                                <button id={`edit-button-2`} onClick={() => handleEditClick(2)}>
                                    Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showEditPopup && (
                <div className='edit-popup'>
                    <div className='edit-popup-content'>
                        <h4>Affiliate Account Details</h4>
                        <div className='edit-popup-input billing-popup'>
                            <div className='input-label billing-label'>
                                <p>Affiliate Account Number</p>
                                <input
                                    type='text'
                                    value={editedDetails}
                                    onChange={(e) => setEditedDetails(e.target.value)}
                                />
                            </div>
                            <div className='input-label billing-label'>
                                <p>Bank Name</p>
                                <BankResult results={results} setResults={setResults} />
                            </div>
                        </div>
                        <div className='edit-popup-button'>
                            <button className='discard' onClick={handleDiscardClick}>Discard</button>
                            <button onClick={handleSaveClick}>Save</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Billing;