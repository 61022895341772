import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './referral.css';
import { MdOutlineContentCopy, MdCheck } from 'react-icons/md';

const Referral = () => {
    const [copied, setCopied] = useState(false);
    const [referralLink, setReferralLink] = useState('');

    useEffect(() => {
        fetchReferralLink();
    }, []);

    const fetchReferralLink = async () => {
        try {
            const authToken = localStorage.getItem('authToken');

            if (!authToken) {
                console.error('Authentication token not found.');
                return;
            }
            const data = {
                token: authToken
            }
            const response = await fetch('https://finclan-api-wunt.onrender.com/user/referral_link', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),

            });
            // console.log(response)
            if (response.ok) {
                const data = await response.json();
                if (data && data.user_referral_link) {
                    setReferralLink(data.user_referral_link);
                } else {
                    console.error('Referral link not found in the response data.');
                }
            } else {
                console.error('Failed to fetch referral link. Status:', response.status);
            }
        } catch (error) {
            console.error('Error fetching referral link:', error);
        }
    };

    const handleCopy = () => {
        if (referralLink) {
            navigator.clipboard.writeText(referralLink);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }
    };

    return (
        <div className='referral-container'>
            <div className='referral-box'>
                <h3 className='big'>Links</h3>
                <p>Share your links to earn commission</p>
                <div className='link-input'>
                    <input type="text" value={referralLink} readOnly />
                    <button className='' onClick={handleCopy}>
                        {copied ? 'Copied!' : 'Copy link'}
                        {copied ? <MdCheck /> : <MdOutlineContentCopy />}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Referral;
