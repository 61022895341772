import React from 'react'
import './mentor.css'
import avatar from '../../assets/avatar.png'
import avatar1 from '../../assets/avatar1.png'
import Button from '../../components/button/Button'

const Mentor = () => {
    return (
        <>
            <div className='mentor py-12'>
                <div className='avatar-container'>
                    <img src={avatar} alt="" />
                    <img src={avatar1} alt="" />
                </div>
                <div className='mentor-info text-center py-6 px-6'>
                    <h2>Personalized sessions for your financial <br /> growth in Forex</h2>
                    <p>Leverage on the privileges from working hands-on with experts at Finclan.</p>
                    <Button link="/signup" text={"Begin Now"} />

                </div>
            </div>
        </>
    )
}

export default Mentor
