import React, { useState, useEffect } from 'react';
import './account.css';
import { useLocation, useHistory } from 'react-router-dom';
import Alert from './../alert/Alert';

const Account = () => {
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [editedDetails, setEditedDetails] = useState('');
    const [editedDetails2, setEditedDetails2] = useState('');
    const [editedIndex, setEditedIndex] = useState(-1);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [ fullName, setFullName] = useState(localStorage.getItem('fullName') || 'John James');
    const [oldPassword, setOldPassword] = useState(''); // New state for old password
    const [newPassword, setNewPassword] = useState(''); // New state for new password

    const history = useHistory();

    const email = localStorage.getItem('email') || 'John@gmail.com';
    const password = '***********';


    const handleEditClickFullName = () => {
        setEditedIndex(0);
        setEditedDetails(fullName);
        setShowEditPopup(true);
    };

    const handleEditClickPassword = () => {
        setEditedIndex(2);
        setShowEditPopup(true);
    };

    const handleDeleteCancel = () => {
        setShowDeletePopup(false);
    };

    const handleSaveClick = async () => {
        setShowEditPopup(false);

        if (editedIndex === 0) {
            try {
                const response = await updateFullNameAPI(editedDetails);
                if (response.ok) {
                    console.log('Full Name updated successfully in API');
                    setFullName(editedDetails);
                    localStorage.setItem('fullName', editedDetails);
                } else {
                    console.error('Failed to update Full Name in API');
                }
            } catch (error) {
                console.error('Error updating Full Name in API:', error);
            }
        } else if (editedIndex === 1) {
            // Update email-related state and localStorage here
        } else if (editedIndex === 2) {
            try {
                const authToken = localStorage.getItem('authToken');
                const updatePasswordUrl = 'https://finclan-api-wunt.onrender.com/user/update/password';

                const response = await fetch(updatePasswordUrl, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        currentPassword: oldPassword, // Include the old password
                        newPassword: newPassword, // Include the new password
                        token: authToken,
                    }),
                });

                if (response.ok) {
                    console.log('Password updated successfully in API');
                    // You can update the password-related state and localStorage here if needed
                } else {
                    console.error('Failed to update Password in API');
                }
            } catch (error) {
                console.error('Error updating Password in API:', error);
            }
        }
    };

    const handleDiscardClick = () => {
        setShowEditPopup(false);
        setEditedDetails('');
        setEditedDetails2('');
        setEditedIndex(-1);
    };

    const handleDeleteClick = () => {
        setShowDeletePopup(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            const deleteAccountUrl = 'https://finclan-api-wunt.onrender.com/user/delete/user';

            const response = await fetch(deleteAccountUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    token: authToken
                })
            });

            if (response.ok) {
                console.log('Account deleted successfully');
                localStorage.clear();
                history.push('/signup');
            } else {
                console.error('Failed to delete account');
            }
        } catch (error) {
            console.error('Error deleting account:', error);
        }
    };

    const updateFullNameAPI = async (newFullName) => {
        try {
            const authToken = localStorage.getItem('authToken');
            const updateFullNameUrl = 'https://finclan-api-wunt.onrender.com/user/update/profile';

            const response = await fetch(updateFullNameUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fullName: newFullName,
                    token: authToken,
                }),
            });

            if (response.ok) {
                console.log('Full Name updated successfully');
                setFullName(newFullName);
                localStorage.setItem('fullName', newFullName);
            } else {
                console.error('Failed to update Full Name');
            }
        } catch (error) {
            console.error('Error updating Full Name:', error);
        }
    };


    return (
        <>
            <div className='account-container'>
                <h1 className='account-heading'>Profile</h1>

                {/* <div role="alert" class="max-w-[300px] p-2 bg-indigo-800 rounded-full items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex">
                    <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">New</span>
                    <span class="font-semibold mr-2 text-left flex-auto">Get the coolest t-shirts</span>
                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="fill-current opacity-75 h-4 w-4"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"></path></svg>
                </div> */}

                <Alert/>

                <div className='account-box'>
                    <h3 className='big'>Account</h3>
                    <div className='account-details'>
                        <div className='details account-unClick'>
                            <div className='details-title'>
                                <p className=''>Full Name</p>
                            </div>
                            <div className='details-info'>
                                <p>{fullName}</p>
                                <button id={`edit-button-0`} onClick={handleEditClickFullName}>
                                    Edit
                                </button>
                            </div>
                        </div>
                        <div className='details account-unClick'>
                            <div className='details-title'>
                                <p className=''>Email</p>
                            </div>
                            <div className='details-info'>
                                <p>{email}</p>
                                <button id={`edit-button-1`}>
                                    Edit
                                </button>
                            </div>
                        </div>
                        <div className='details account-unClick'>
                            <div className='details-title'>
                                <p className=''>Password</p>
                            </div>
                            <div className='details-info'>
                                <p>{password}</p>
                                <button id={`edit-button-2`} onClick={handleEditClickPassword}>
                                    Edit
                                </button>
                            </div>
                        </div>
                    </div>
                    <button className='warning' onClick={handleDeleteClick}>Delete my account</button>
                </div>
            </div>
            {showDeletePopup && (
                <div className='popup-container'>
                    <div className='popup popup-p'>
                        <p>Are you sure you want to delete your Finclan community account? You will not have access to our resources after this.</p>
                        <div className='popup-buttons'>
                            <button onClick={handleDeleteConfirm}>Continue</button>
                            <button onClick={handleDeleteCancel}>Go Back</button>
                        </div>
                    </div>
                </div>
            )}
            {showEditPopup && editedIndex === 0 && (
                <div className='edit-popup'>
                    <div className='edit-popup-content'>
                        <h4>Full Name</h4>
                        <div className='edit-popup-input'>
                            <div className='input-label'>
                                <input
                                    type='text'
                                    value={editedDetails}
                                    onChange={(e) => setEditedDetails(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='edit-popup-button'>
                            <button className='discard' onClick={handleDiscardClick}>Discard</button>
                            <button onClick={handleSaveClick}>Save</button>
                        </div>
                    </div>
                </div>
            )}

            {showEditPopup && editedIndex === 2 && (
                <div className='edit-popup'>
                    <div className='edit-popup-content'>
                        <h4>Password</h4>
                        <div className='edit-popup-input'>
                            <div className='input-label'>
                                <input
                                    type='password' // Change input type to 'password' to hide characters
                                    placeholder='Old Password'
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />
                            </div>
                            <div className='input-label'>
                                <input
                                    type='password' // Change input type to 'password' to hide characters
                                    placeholder='New Password'
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='edit-popup-button'>
                            <button className='discard' onClick={handleDiscardClick}>Discard</button>
                            <button onClick={handleSaveClick}>Save</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Account;









// Dynamic Map method

// import React, { useState, useEffect } from 'react';
// import './account.css';
// import { useLocation, useHistory } from 'react-router-dom'; // Import useLocation hook
// import { useAuth } from '../../hooks/useAuth/AuthContext'; // Import your authentication context


// const Account = () => {
//     const [showEditPopup, setShowEditPopup] = useState(false);
//     const [editedDetails, setEditedDetails] = useState('');
//     const [editedDetails2, setEditedDetails2] = useState('');
//     const [editedIndex, setEditedIndex] = useState(-1);
//     const [showDeletePopup, setShowDeletePopup] = useState(false);
//     const [fullName, setFullName] = useState(localStorage.getItem('fullName') || 'John James');


//     const { user, logout } = useAuth(); // Use your authentication context to access user data
//     // const location = useLocation(); // Get the current location
//     const history = useHistory();

//     // Retrieve user details from localStorage
//     // const fullName = localStorage.getItem('fullName') || 'John James';
//     const email = localStorage.getItem('email') || 'John@gmail.com';
//     const password = '***********'; // Displaying password in clear text is not recommended


//     useEffect(() => {
//         // Check if the user is authenticated based on the user context
//         if (!user) {
//             // Check if the authentication token is present in localStorage
//             const authToken = localStorage.getItem('authToken');
//             if (!authToken) {
//                 // If neither user context nor authToken is present, redirect to the login page
//                 history.push('/signup');
//             }
//         }
//     }, [user, history]);

//     const handleEditClick = (index) => {
//         if (Info[index].title === 'Email') {
//             // Disable the button if editing the Email field
//             const editButton = document.getElementById(`edit-button-${index}`);
//             editButton.disabled = true;
//         } else {
//             // Show the edit popup for other fields
//             setEditedIndex(index);
//             setEditedDetails(Info[index].details);
//             setEditedDetails2(Info[index].details2 || '');
//             setShowEditPopup(true);
//         }
//     };

//     const handleSaveClick = () => {
//         // Update the detail with the new value
//         const updatedInfo = [...Info];
//         updatedInfo[editedIndex].details = editedDetails;
//         if (Info[editedIndex].details2) {
//             updatedInfo[editedIndex].details2 = editedDetails2;
//         }

//         // Save the updated info and hide the popup
//         setShowEditPopup(false);
//         // setInfo(updatedInfo);

//         // Call the function to update Full Name
//         updateFullName(editedDetails);
//     };

//     const handleDiscardClick = () => {
//         setShowEditPopup(false);
//         setEditedDetails('');
//         setEditedDetails2('');
//         setEditedIndex(-1);
//     };

//     const handleDeleteClick = () => {
//         setShowDeletePopup(true);
//     };

//     // const handleDeleteConfirm = () => {
//     //     window.location.href = '/signup';
//     //     setShowDeletePopup(true);
//     // };

//     const handleDeleteConfirm = async () => {
//         try {
//             const authToken = localStorage.getItem('authToken'); // Get the authentication token
//             const deleteAccountUrl = 'https://finclan-api-wunt.onrender.com/user/delete/user'; // Replace with your API endpoint for account deletion

//             const response = await fetch(deleteAccountUrl, {
//                 method: 'DELETE', // Change the method to DELETE
//                 headers: {
//                     'Content-Type': 'application/json',
//                     Authorization: `Bearer ${authToken}`, // Include the authentication token in the request headers
//                 },
//             });

//             if (response.ok) {
//                 // Handle successful account deletion, e.g., show a success message
//                 console.log('Account deleted successfully');

//                 // Clear user data from localStorage
//                 localStorage.removeItem('authToken');
//                 localStorage.removeItem('fullName');
//                 localStorage.removeItem('email');

//                 // Redirect to the login page
//                 history.push('/signup');
//             } else {
//                 // Handle error, e.g., show an error message
//                 console.error('Failed to delete account');
//             }
//         } catch (error) {
//             console.error('Error deleting account:', error);
//         }
//     };


//     const handleDeleteCancel = () => {
//         setShowDeletePopup(false);
//     };

//     const updateFullName = async (newFullName) => {
//         try {
//             const authToken = localStorage.getItem('authToken'); // Get the authentication token
//             const updateFullNameUrl = 'https://finclan-api-wunt.onrender.com/user/update/profile'; // Replace with your API endpoint

//             const response = await fetch(updateFullNameUrl, {
//                 method: 'PUT', // Change the method to PUT
//                 headers: {
//                     'Content-Type': 'application/json',
//                     Authorization: `Bearer ${authToken}`, // Include the authentication token in the request headers
//                 },
//                 body: JSON.stringify({ fullName: newFullName }), // Send the new full name in the request body
//             });

//             if (response.ok) {
//                 // Handle success, e.g., show a success message
//                 console.log('Full Name updated successfully');

//                 // Update the fullName state with the new value
//                 setFullName(newFullName);

//                 // Update the value in localStorage as well
//                 localStorage.setItem('fullName', newFullName);
//             } else {
//                 // Handle error, e.g., show an error message
//                 console.error('Failed to update Full Name');
//             }
//         } catch (error) {
//             console.error('Error updating Full Name:', error);
//         }
//     };



//     const Info = [
//         {
//             // label: 'First name',
//             // label2: 'Surname',
//             title: 'Full Name',
//             details: fullName,
//             // details2: ' James'
//         },
//         {
//             // label: '',
//             title: 'Email',
//             details: email,
//             // details2: ''

//         },
//         {
//             // label: 'Password',
//             title: 'Password',
//             details: password, // Displaying password in clear text is not recommended for security reasons
//             // details2: ''
//         },
//     ];

//     return (
//         <>
//             <div className='account-container'>
//                 <h1 className='account-heading'>Profile</h1>
//                 <div className='account-box'>
//                     <h3 className='big'>Account</h3>
//                     <div className='account-details'>
//                         {Info.map((personal, index) => (
//                             <div key={index} className='details account-unClick'>
//                                 <div className='details-title'>
//                                     <p className=''>{personal.title}</p>
//                                 </div>
//                                 <div className='details-info'>
//                                     <p>{personal.details}{personal.details2}</p>
//                                     <button id={`edit-button-${index}`} onClick={() => handleEditClick(index)}>
//                                         Edit
//                                     </button>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                     <button className='warning' onClick={() => setShowDeletePopup(true)}>Delete my account</button>
//                 </div>
//             </div>
//             {showDeletePopup && (
//                 <div className='popup-container'>
//                     <div className='popup popup-p'>
//                         <p>Are you sure you want to delete your Finclan community account? you will not have access to our resources after this.</p>
//                         <div className='popup-buttons'>
//                             <button onClick={handleDeleteConfirm}>Continue</button>
//                             <button onClick={handleDeleteCancel}>Go Back</button>
//                         </div>
//                     </div>
//                 </div>
//             )}
//             {showEditPopup && (
//                 <div className='edit-popup'>
//                     <div className='edit-popup-content'>
//                         <h4>{Info[editedIndex].title}</h4>
//                         <div className='edit-popup-input'>
//                             <div className='input-label'>
//                                 <p>{Info[editedIndex].label}</p>

//                                 <input
//                                     type='text'
//                                     value={editedDetails}
//                                     onChange={(e) => setEditedDetails(e.target.value)}
//                                 />
//                             </div>
//                             {/* <div className='input-label' style={{ display: Info[editedIndex].details2.length > 0 ? 'flex' : 'none' }}>
//                                 <p>{Info[editedIndex].label2}</p>

//                                 <input
//                                     type='text'
//                                     value={editedDetails2}
//                                     onChange={(e) => setEditedDetails2(e.target.value)}
//                                 />
//                             </div> */}
//                         </div>
//                         <div className='edit-popup-button'>
//                             <button className='discard' onClick={handleDiscardClick}>Discard</button>
//                             <button onClick={handleSaveClick}>Save</button>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </>
//     );
// };

// export default Account;
