import React, { useState } from 'react';
import { FaDiscord } from "react-icons/fa";
import './header.css'
import bannerImage from "../../assets/banner.png";
import Button from '../../components/button/Button';

const Header = () => {
    const [loaded, setLoaded] = useState(false);

    const handleImageLoad = () => {
        setLoaded(true);
    }

    return (
        <>
            {!loaded && <div className="loading-spinner"></div>}
            <header style={{ visibility: loaded ? 'visible' : 'hidden' }}>
                <div className='banner-text'>
                    <h1>Simplifying finance. <br /> Grow with the clan.</h1>
                    <p>Join our ever-growing finance community. Get simplified forex trading, build your finance with the clan and more.</p>
                    <Button link="https://bit.ly/finclan-discord" text="Join our discord group" icon={<FaDiscord />} style={{ backgroundColor: '#5865f2' }} />
                </div>
                <img className='banner-image' src={bannerImage} alt="" onLoad={handleImageLoad} />
            </header>
        </>
    )
}

export default Header;
