import React from 'react'
import Article from '../../components/article/Article'
import './members.css'
import Button from '../../components/button/Button'
// import Button from '../../components/button/Button'

const Members = () => {

    const myDetails = {
        heading: 'Clan Members',
        details: 'Clan members get access to exclusive content, live updates, meetups & more. Want to get better at your finance? Chat with us and join the finance clan. Become a member today! 👍🏼'
    }

    const myInfo = {
        heading: 'What You Get',
        list: [
            '📚  Trading resources',
            '📶  Signals',
            '🎬  Live trading sessions',
            '💹  In-depth market analysis',
            '📰  NFP and other market news',
            '🤲  Discounts on all products from courses and webinars'

        ]
    };

    return (
        <div className='mem-section'>
            <div className='mem-box'>
                <div className='mem-heading'>
                    <h2><span>Clan </span> Members</h2>
                </div>
                <div className='mem-details'>
                    <Article heading={myDetails.heading} details={myDetails.details} />
                    <br />
                    <Article heading={myInfo.heading} />
                    <ul>
                        {myInfo.list.map(item => (
                            <li key={item}> {item} </li>
                        ))}
                    </ul>

                    <Button link="/signup" text={"Join Our Clan"} style={{ margin: '20px 0 10px 0' }} />
                </div>

            </div>
        </div>
    )
}

export default Members;
