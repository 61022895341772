import React, {useEffect} from 'react';
import './affiliate.css'
import affiliate from '../../assets/affiliate.png'
import Button from './../../components/button/Button';
import { useAuth } from '../../hooks/useAuth/AuthContext'; // Import your authentication context
import { useLocation, useHistory } from 'react-router-dom'; // Import useLocation hook



const Affiliate = () =>{
    const { user, logout } = useAuth(); // Use your authentication context to access user data
    // const location = useLocation(); // Get the current location
    const history = useHistory();

    // useEffect(() => {
    //     // Check if the user is authenticated based on the user context
    //     if (!user) {
    //         // Check if the authentication token is present in localStorage
    //         const authToken = localStorage.getItem('authToken');
    //         if (!authToken) {
    //             // If neither user context nor authToken is present, redirect to the login page
    //             history.push('/signup');
    //         }
    //     }
    // }, [user, history]);

    return(
        <div className="affiliate-conatiner">
            <div className="affiliate-box">
                <img alt="" src={affiliate} />
                <h3 className="big">Finclan affiliate program</h3>
                <p>When you sign up, you will get a special link that you can share with your friends. If <br />someone purchases a community spot or buys a course through that link, you will get 5%.</p>
                <Button link="/Dashboard" text="Join" />
            </div>
        </div>
    );
}

export default Affiliate;
