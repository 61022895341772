import React from 'react'
import PayOption from '../../components/payOption/PayOption'

const Payment = () => {
    return (
        <>
            <section className="payment container__ forms">
                <PayOption />
            </section>
        </>
    )
}

export default Payment;
