import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Create a context
const AuthContext = createContext();

// Create a custom hook to access the context
export function useAuth() {
    return useContext(AuthContext);
}

// Create a provider component
export function AuthProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const history = useHistory();

    const login = () => {
        // You can implement your login logic here.
        // When login is successful, set isLoggedIn to true.
        setIsLoggedIn(true);
    };

    const logout = () => {
        // You can implement your logout logic here.
        // When logout is successful, set isLoggedIn to false.
        // Remove the authentication token from localStorage
        localStorage.removeItem('authToken');
        localStorage.removeItem('fullName');
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.clear();

        // Perform any additional logout tasks (e.g., resetting user state)
        // ...

        // Redirect the user to the login page
        history.push('/signup'); // Use the history object from useHistory
        setIsLoggedIn(false);
    };

    // Check for an authentication token in local storage when the component mounts
    useEffect(() => {
        // Check the user's role based on your data (e.g., 'member', 'affiliate')
        const userRole = localStorage.getItem('userRole');
        setUserRole(userRole);
        // console.log('Auth', userRole);

        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            // Token found, consider the user authenticated
            setIsLoggedIn(true);
        }
    }, []);

    // Listen for changes in userRole in localStorage
    useEffect(() => {
        const handleUserRoleChange = (e) => {
            if (e.key === 'userRole') {
                setUserRole(e.newValue);
            }
        };

        window.addEventListener('storage', handleUserRoleChange);

        return () => {
            window.removeEventListener('storage', handleUserRoleChange);
        };
    }, []);

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout, userRole }}>
            {children}
        </AuthContext.Provider>
    );
}
