import React from 'react'
import './course.css'
import banner1 from '../../assets/banner1.png'
import CourseList from '../../containers/courseList/CourseList'
import PageBanner from '../../components/pageBanner/PageBanner'

const Course = () => {
    return (
        <>
            <PageBanner heading={"Courses"}/>
            <CourseList />

        </>
    )
}

export default Course
