import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Index from './pages/home/Index';
import Course from './pages/course/Course';
import Mentorship from './pages/mentorship/Mentorship';
// import './App.css'
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Login from './pages/login/Login';
import FinclanAffiliate from './pages/finclanAffiliate/FinclanAffiliate'
import Payment from './pages/paymenet/Payment';
import UsdtPay from './pages/usdtPay/UsdtPay';
import Profile from './pages/profile/Profile';
import Dashboard from './pages/dashboard/Dashboard'
import './tailwind.css';
// import React, { useEffect } from 'react';
import PaystackHook from './components/paystack/paystack';
import LoginForm from './components/form/LoginForm';
import { AuthProvider } from '../src/hooks/useAuth/AuthContext'; // Import the AuthProvider
import { LocalStorageProvider } from '../src/hooks/storageProvider/storageProvider';
import Subscribe from './pages/subscribe/Subscribe';
import ResetPassword from './components/resetPassword/ResetPassword';
import Pricing from './components/pricing/Pricing';
import NewPassword from './components/newPassword/NewPassword';


function App() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <Router>
      {/* Wrap your application with AuthProvider */}
      <AuthProvider>
        <LocalStorageProvider>
          <Navbar />
          <Switch>
            <Route exact path="/" component={Index} />
            <Route path="/Course" component={Course} />
            <Route path="/Mentorship" component={Mentorship} />
            <Route path="/FinclanAffiliate" component={FinclanAffiliate} />
            <Route path="/signup" component={Login} />
            <Route path="/Payment" component={Payment} />
            <Route path="/UsdtPay" component={UsdtPay} />
            <Route path="/Profile" component={Profile} />
            <Route path="/Dashboard" component={Dashboard} />
            <Route path="/Email-Confirm" component={Subscribe}/>
            <Route path="/Reset-Password" component={ResetPassword}/>
            <Route path="/Pricing" component={Pricing}/>
            <Route path="/Change-Password" component={NewPassword}/>
            {/* <Route path="/PaystackHook" component={PaystackHook}/> */}
          </Switch>
          <Footer />
        </LocalStorageProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;



// import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import Index from './pages/home/Index';
// import Course from './pages/course/Course';
// import Mentorship from './pages/mentorship/Mentorship';
// import Navbar from './components/navbar/Navbar';
// import Footer from './components/footer/Footer';
// import Login from './pages/login/Login';
// import FinclanAffiliate from './pages/finclanAffiliate/FinclanAffiliate';
// import Payment from './pages/paymenet/Payment';
// import UsdtPay from './pages/usdtPay/UsdtPay';
// import Profile from './pages/profile/Profile';
// import Dashboard from './pages/dashboard/Dashboard';
// import Subscribe from './pages/subscribe/Subscribe';
// import { AuthProvider, useAuth } from '../src/hooks/useAuth/AuthContext';

// function App() {
//   return (
//     <Router>
//       <AuthProvider>
//         <Navbar />
//         <Switch>
//           <Route exact path="/" component={Index} />
//           <Route path="/Course" component={Course} />
//           <Route path="/Mentorship" component={Mentorship} />
//           <Route path="/FinclanAffiliate" component={FinclanAffiliate} />
//           <Route path="/signup" component={Login} />
//           <Route path="/Payment" component={Payment} />
//           <Route path="/UsdtPay" component={UsdtPay} />
//           <PrivateRoute path="/Profile" allowedRoles={'member'} component={Profile} />
//           <PrivateRoute path="/Dashboard" allowedRoles={'non_member'} component={Dashboard} />
//           <Route path="/Email-Confirm" component={Subscribe} />
//         </Switch>
//         <Footer />
//       </AuthProvider>
//     </Router>
//   );
// }

// // A PrivateRoute component that checks user roles before rendering
// function PrivateRoute({ component: Component, allowedRoles, ...rest }) {
//   const { userRole } = useAuth();
//   console.log('userRole:', userRole);
//   console.log('allowedRoles:', allowedRoles);

//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         if (allowedRoles.includes(userRole)) {
//           return <Component {...props} />;
//         } else {
//           return <Redirect to="/signup" />;
//         }
//       }}
//     />
//   );
// }

// export default App;

