// Import the necessary modules at the top
import React, { useEffect, useState } from 'react';
import './apiDetails.css'

function ApiDetails({ accountNumber, bankCode }) {
    const [accountName, setAccountName] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (accountNumber && bankCode) {
                    // Example of the POST request JSON
                    const postRequestBody = {
                        account_number: accountNumber,
                        bank_code: bankCode,
                    };

                    const response = await fetch('https://finclan-api-wunt.onrender.com/payment/get_account_name', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(postRequestBody),
                    });

                    if (response.ok) {
                        const data = await response.json();
                        // console.log('API Response Data:', data);
                        const accountDetails = data.account_details.data;
                        const resolvedAccountNumber = accountDetails.account_number;
                        // console.log('API Response Data:', resolvedAccountNumber);

                        if (resolvedAccountNumber === accountNumber) {
                            const name = accountDetails.account_name;
                            setAccountName(name);
                            localStorage.setItem("accountName", name)
                        } else {
                            setError('Resolved account number does not match input.');
                        }
                    } else {
                        // Handle non-200 status codes
                        setError('API request failed with status ' + response.status);
                    }
                }
            } catch (error) {
                // Handle other errors
                setError('API request error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [accountNumber, bankCode]);

    return (
        <div className="acct-name">
            {loading ? (
                <p>Loading...</p>
            ) : (
                error ? <p>Error: {error}</p> : <p>Account Name: {accountName}</p>
            )}
            <hr />
            <p>I confirm and agree that this is the account i intend to withdraw to. Withdrawals are irreversible.</p>
        </div>
    );
}

export default ApiDetails;
