import React from 'react'
import './pageBanner.css'

const PageBanner = ( {heading} ) => {
    return (
        <div className='course'>
                <div className='course-heading'>
                    <h2>{heading}</h2>
                </div>
            </div>
    )
}

export default PageBanner
