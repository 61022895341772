import React, { useEffect, useState } from 'react';
import './popUp.css';
import graph from '../../assets/payment.png';
import PaystackHook from '../paystack/paystack';

const PopUp = ({ updatePaymentStatus, email, autoClickButton, course, price, popupType, postData }) => {
    const [isVisible, setIsVisible] = useState(true);

    // This effect will automatically close the popup after 10 seconds
    useEffect(() => {
        if (autoClickButton) {
            // Automatically close the popup after 10 seconds
            const timeoutId = setTimeout(() => {
                setIsVisible(false);
            }, 10000); // 10 seconds in milliseconds

            // Clear the timeout if the component unmounts
            return () => clearTimeout(timeoutId);
        }
    }, [autoClickButton]);
    // console.log(price)

    return (
        <>
            {isVisible && (
                <div className="modal-overlay">
                    <div className="modal">
                        <img src={graph} alt="" />
                        <p>You are being redirected to <span>Paystack</span> to make payments</p>
                        {popupType === "signup" && (
                            <PaystackHook
                                updatePaymentStatus={updatePaymentStatus}
                                email={email}
                                currency="NGN"
                                amount={price}
                                apiEndpoint="https://finclan-api-wunt.onrender.com/payment/membership"
                                buttonText="Pay for Membership"
                            />
                        )}
                        {popupType === "courselist" && (
                            <PaystackHook
                                email={email}
                                currency="NGN"
                                course={course}
                                amount={price}
                                apiEndpoint="https://finclan-api-wunt.onrender.com/payment/course_purchase"
                                buttonText="Pay for Course"
                                updatePaymentStatus={updatePaymentStatus}
                                // disablePaymentStatusUpdate={true}
                                postData={postData} // Pass the postData to PaystackHook
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default PopUp;
