import React,{useEffect} from 'react';
import Layout from './../../containers/layout/Layout';
import './dashboard.css'
import { useAuth } from '../../hooks/useAuth/AuthContext'; // Import your authentication context
import { useLocation, useHistory } from 'react-router-dom'; // Import useLocation hook


const Dashboard = () => {
    const { user, logout } = useAuth(); // Use your authentication context to access user data
    // const location = useLocation(); // Get the current location
    const history = useHistory();

    useEffect(() => {
        // Check if the user is authenticated based on the user context
        if (!user) {
            // Check if the authentication token is present in localStorage
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                // If neither user context nor authToken is present, redirect to the login page
                history.push('/signup');
            }
        }
    }, [user, history]);

    return (
        <div className='dashboard-container'>
            <Layout />
        </div>
    );
}

export default Dashboard;

// import React, { useEffect } from 'react';
// import Layout from './../../containers/layout/Layout';
// import './dashboard.css';
// import { useAuth } from '../../hooks/useAuth/AuthContext';
// import { useHistory } from 'react-router-dom';

// const Dashboard = () => {
//     const { user } = useAuth();
//     const history = useHistory();

//     useEffect(() => {
//         if (!user) {
//             const authToken = localStorage.getItem('authToken');
//             console.log(authToken)
//             if (!authToken) {
//                 // Redirect to /signup if not authenticated
//                 history.push('/signup');
//             }
//         }
//     }, [user, history]);

//     // If you're in the process of redirecting, return null to prevent rendering
//     if (!user) {
//         console.log("NotUser");
//         return null;
//     }

//     // If the user is authenticated, render the Layout component
//     console.log("IsUser");
//     return (
//         <div className='dashboard-container'>
//             <Layout />
//         </div>
//     );
// }

// export default Dashboard;