import React, { useState, useEffect } from 'react';
import Button from '../button/Button';
import './navbar.css';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth/AuthContext';
import { useHistory } from 'react-router-dom';


const Navbar = () => {
	const Links = [
		{ name: 'Home', link: '/' },
		{ name: 'Courses', link: '/Course' },
		{ name: 'Pricing', link: '/Pricing' },
		{ name: 'Mentorship', link: '/Mentorship' },
		{ name: 'Finclan Affiliate', link: '/FinclanAffiliate' },
		{ name: 'Finclan Signals', link: 'https://paystack.com/pay/finclan-signals' },
	];

	const { isLoggedIn, logout } = useAuth(); // Access the login state and logout function

	const authToken = localStorage.getItem('authToken');

	const [open, setOpen] = useState(false);
	const [showNavbar, setShowNavbar] = useState(false);
	const [isIconClicked, setIsIconClicked] = useState(false);
	const history = useHistory();

	const redirectToLoginPage = () => {
		// Redirect to the login page
		history.push('/signup');

		// Reload the page
		window.location.reload();
	}
	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.pageYOffset;
			if (scrollPosition > 500) {
				setShowNavbar(true);
			} else {
				setShowNavbar(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		if (isIconClicked) {
			document.body.classList.add('grey-bg');
		} else {
			document.body.classList.remove('grey-bg');
		}
	}, [isIconClicked]);

	const handleIconClick = () => {
		if (window.matchMedia("(max-width: 768px)").matches) {
			setIsIconClicked(!isIconClicked);
			setOpen(!open);
		}
	};

	const handleLinkClick = (fromButton) => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
		if (fromButton) {
			setIsIconClicked(false);
			setOpen(false);
		}
	};

	return (
		<div className={`navbar__ w-full top-0 left-0 fixed ${showNavbar ? ' shadow-md ' : ''} ${open ? 'shadow_' : 'shadow-md'}`}>
			<div className="nav__ md:flex items-center justify-between bg-white py-4 md:px-20 px-7">
				<div className="font-bold text-2xl cursor-pointer flex items-center">
					<Link to="/" onClick={handleLinkClick}>
						<img src={logo} alt="logo" />
					</Link>
				</div>
				<div onClick={handleIconClick} className="text-3xl right-8 top-6 cursor-pointer md:hidden">
					<ion-icon name={open ? 'close' : 'menu'}></ion-icon>
				</div>
				<div className={`nav md:flex md:items-center md:pb-0 pb-8 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 fast-ease-in ${open ? 'top-16 pt-8' : 'top-[-490px] '}`}>
					<ul className="nav-ul">
						{Links.map((link) => (
							<li onClick={() => handleLinkClick(false)} key={link.name} className="md:ml-8 text-xl md:my-0 mb-7 relative">
								{link.link.startsWith('http') ? (
									<a href={link.link} className="text-gray-800 hover:text-gray-400 duration-500" target="_blank" rel="noopener noreferrer">
										{link.name}
									</a>
								) : (
									<Link to={link.link} className="text-gray-800 hover:text-gray-400 duration-500" onClick={() => handleLinkClick(true)}>
										{link.name}
									</Link>
								)}
								<div className="hover-circle"></div>
							</li>
						))}
					</ul>

					{isLoggedIn || authToken ? (
						<Button myClass={"login-btn-link"} clickAction={() => { logout(); handleLinkClick(true); }} link="./signup" className="login-btn" text="Log out" />
						// <Link>
						// 	<button className="logout-btn" onClick={() => { logout(); handleLinkClick(true); }}>Logout</button>
						// </Link>
					) : (
						<Button myClass={"login-btn-link"} clickAction={() => {
							handleLinkClick(true); redirectToLoginPage()
						}} link="./signup" className="login-btn" text="Log in" />
					)}

					{/* {isLoggedIn ? (
						<Button myClass={"login-btn-link"} clickAction={logout} className="login-btn" text="Logout" />
					) : (
						<Button myClass={"login-btn-link"} clickAction={() => handleLinkClick(true)} link="./signup" className="login-btn" text="Login" />
					)} */}
				</div>
			</div>
		</div>
	);
};

export default Navbar;





// import { useEffect, useRef } from "react";
// import { useState } from 'react';
// import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
// import "./navbar.css";
// import logo from '../../assets/logo.png';
// import Button from "../button/Button";

// const Navbar = () => {
// 	const navRef = useRef();
// 	const headerRef = useRef(); // added a reference to the header element
// 	const [isOpen, setIsOpen] = useState(false);

// 	const showNavbar = () => {
// 		navRef.current.classList.toggle(
// 			"responsive_nav"
// 		);
// 		setIsOpen(!isOpen);
// 	};

// 	useEffect(() => {
// 		document.body.style.backgroundColor = isOpen ? "#ccc" : "#fff";
// 	}, [isOpen]);

// 	useEffect(() => {
// 		// added an event listener to the scroll event
// 		window.addEventListener('scroll', handleScroll);
// 		return () => {
// 			window.removeEventListener('scroll', handleScroll);
// 		}
// 	}, []);

// 	const handleScroll = () => {
// 		// added a check for the window scroll position
// 		if (window.pageYOffset > 200) {
// 			headerRef.current.classList.add('header-scroll');
// 		} else {
// 			headerRef.current.classList.remove('header-scroll');
// 		}
// 	}

// 	return (
// 		<div className="header" ref={headerRef}>
// 			<img src={logo} alt="" />
// 			<nav ref={navRef}>
// 				<a href="/#">Home</a>
// 				<a href="/#">Course</a>
// 				<a href="/#">Mentorship</a>
// 				<a href="/#">Finclan Affiliate</a>
// 			</nav>
// 			<a href="/#" className="login-btn">
// 				<Button text="Login"/>
// 			</a>
// 			<button className="nav-btn" onClick={showNavbar}>
// 				{isOpen ? <RiCloseLine color="#000" /> : <RiMenu3Line color="#000" />}
// 			</button>
// 		</div>
// 	);
// }

// export default Navbar;




// import { useState } from "react";
// import "./navbar.css";
// import logo from "../../assets/logo.png";

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <nav className="navbar">
//       <div className="navbar__container">
//         <a href="/" className="navbar__logo">
//           <img src={logo} alt="Logo" />
//         </a>
//         <div className="navbar__toggle" onClick={toggleMenu}>
//           <span className="bar"></span>
//           <span className="bar"></span>
//           <span className="bar"></span>
//         </div>
//         <ul className={isOpen ? "navbar__menu active" : "navbar__menu"}>
//           <li className="navbar__item">
//             <a href="/" className="navbar__link">
//               Home
//             </a>
//           </li>
//           <li className="navbar__item">
//             <a href="/" className="navbar__link">
//               About
//             </a>
//           </li>
//           <li className="navbar__item">
//             <a href="/" className="navbar__link">
//               Services
//             </a>
//           </li>
//           <li className="navbar__item">
//             <a href="/" className="navbar__link">
//               Blog
//             </a>
//           </li>
//           <li className="navbar__item">
//             <a href="/" className="navbar__link">
//               Contact
//             </a>
//           </li>
//         </ul>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;
