import React from 'react'

const Article = ({style, heading, details, className}) => {
    return (
        <div className={className}>
            <h3 className={style}>{heading}</h3>
            <p>{details}</p>
        </div>
    )

}

export default Article
