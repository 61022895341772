import React, { useEffect } from "react";
import { usePaystackPayment } from "react-paystack";

const PaystackHook = (props) => {
  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
  // console.log(publicKey);

  // Define the onSuccess function
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    // console.log(reference);
    // console.log(reference.reference);
    // Call the callback to update payment status in LoginForm
    if (!props.disablePaymentStatusUpdate) {
      props.updatePaymentStatus(reference.reference);
    }
    // // props.updatePaymentStatus(reference.reference);
    console.log('run next code')
    localStorage.setItem('courseReference', reference.reference);
    const apiUserToken = localStorage.getItem('userToken')
    // Prepare the data to post to the API
    const postData = {
      reference: reference.reference,
      // token: , // Pass the token prop
    };

    if (props.apiEndpoint === "https://finclan-api-wunt.onrender.com/payment/membership") {
      // For the membership API, include only the token and reference
      postData.token = apiUserToken
      delete postData.course;
      delete postData.email;
    } else if (props.apiEndpoint === "https://finclan-api-wunt.onrender.com/payment/course_purchase") {
      // For the course purchase API, include course and email
      postData.course = props.course; // Pass the course prop
      postData.email = props.email; // Pass the email prop
      delete postData.token;
    }
    console.log('post data1', postData);


    // Make a POST request to the API using the provided endpoint
    fetch(props.apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Include any additional headers as needed
      },
      body: JSON.stringify(postData),
    })
      .then(async (response) => {
        if (response.ok) {
          // const reponseData = await response.json()
          // console.log('API Response', reponseData);
          console.log('Data posted successfully to the API', postData);
        } else {
          console.error('Failed to post data to the API');
        }
      })
      .catch((error) => {
        console.error('Error posting data:', error);
      });

    localStorage.setItem('Payment Successful', reference.reference);
    console.log('post data2', postData);
  };


  // You can call this function anything
  const onClose = () => {
    // Implementation for whatever you want to do when the Paystack dialog is closed.
    console.log("closed");
  };

  // Initialize payment outside of useEffect
  const config = {
    reference: new Date().getTime().toString(),
    email: props.email,
    currency: props.currency,
    amount: props.amount * 100, // Amount is in the country's lowest currency. E.g., Kobo, so 20000 kobo = N200
    publicKey,
  };
  const initializePayment = usePaystackPayment(config);

  useEffect(() => {
    // Add a small delay before clicking the button
    const delayClick = setTimeout(() => {
      const button = document.getElementById("paystackButton");
      if (button) {
        button.click();
      }
    }, 4000); // Adjust the delay as needed

    return () => clearTimeout(delayClick); // Clean up the timeout on unmount
  }, []);

  return (
    <div style={{ display: "none" }}>
      <button
        id="paystackButton"
        onClick={() => {
          initializePayment(onSuccess, onClose);
        }}
      >
        {props.buttonText}
      </button>
    </div>
  );
};

export default PaystackHook;
