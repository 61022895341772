// LocalStorageContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const LocalStorageContext = createContext();

export function useLocalStorage() {
    return useContext(LocalStorageContext);
}

export function LocalStorageProvider({ children }) {
    const [paymentStatus, setPaymentStatus] = useState(localStorage.getItem('Payment Successful'));

    useEffect(() => {
        // Add an event listener for changes to localStorage
        const handleStorageChange = (event) => {
            if (event.key === 'Payment Successful' && event.newValue !== null) {
                setPaymentStatus(event.newValue);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            // Remove the event listener when the component unmounts
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <LocalStorageContext.Provider value={{ paymentStatus, setPaymentStatus }}>
            {children}
        </LocalStorageContext.Provider>
    );
}
