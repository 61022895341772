import React from 'react'
import Header from '../../containers/header/Header'
import Members from '../../containers/members/members'
import JoinUs from '../../containers/joinUs/JoinUs'
import Testimonials from '../../containers/testimonials/Testimonials'
import Faq from '../../components/faq/Faq'
import United from '../../containers/united/United'
import Discount from '../../containers/discount/Discount'

const Index = () => {
    return (
        <>
            <Header />
            <Members />
            <div className='gradient'>
                <JoinUs />
                <Testimonials />
            </div>
            <United />
            <Faq />
            <Discount />
        </>
    )
}

export default Index
