import React, { useState, useEffect } from 'react';
import './alert.css'; // Import your CSS file

function Alert({ type, message }) {
    const [isVisible, setIsVisible] = useState(true);

    // Automatically hide the alert after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            hideAlert();
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    const hideAlert = () => {
        setIsVisible(false);
    };

    return (
        <div className='alert-container'>
            <div className={`alert ${type} ${isVisible ? 'show' : 'hide'}`}>
                {/* <span className="fas fa-exclamation-circle"></span> */}
                <span className="msg">{message}</span>
                {/* <div className="close-btn" onClick={hideAlert}>
                    <span className="fas fa-times"></span>
                </div> */}
            </div>

        </div>
    );
}

export default Alert;
