// SearchOutput.js
import React from 'react';

const SearchOutput = ({ result, className, onResultClick }) => {
  const handleClick = () => {
    onResultClick(result); // Call the parent's onResultClick function with the clicked result
  };

  return (
    <div
      className={className}
      onClick={handleClick}
    >
      {result}
    </div>
  );
};

export default SearchOutput;
