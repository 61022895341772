// AcctName Component
import React from 'react';

function AcctName({ userName }) {
    return (
        <div>
            {userName ? <p>User Name: {userName}</p> : null}
        </div>
    );
}

export default AcctName;