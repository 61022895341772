import React from "react";
import './joinus.css'

const details = [
    {
        heading: "1",
        title: "Learn how to trade",
        description: ["You can ask questions about the systems, how trades are made, and personal advice to improve your own trading/finance."],
    },
    {
        heading: "2",
        title: "View how we do it",
        description: ["You get to see how to manage accounts by seeing live market commentaries, what trades to take, and how to manage risks. Showing how to leverage $100k funded account with entries/exits with full analysis!"],
    },
    {
        heading: "3",
        title: "Chat with clan members",
        description: ["Get networked with others and build valuable relationships. Learn from them while you build and inspire the clan with your own story."],
    }
];

function JoinUs() {
    return (
        <section className="join-us">
            <div className="join-us-heading mx-auto p-4 my-6 space-y-2 text-center">
                <h2>Why Join Us?</h2>
            </div>
            <div className="container mx-auto grid justify-center gap-4 sm:grid-cols-2 lg:grid-cols-3">

                {details.map((info, index) => (
                    <div key={index} className="join-us-grid flex flex-col items-center p-4">
                        {/* <div className="grid-heading">
                            <img src={blob} alt="" />

                        </div> */}
                        <h1 className="num-img">{info.heading}</h1>
                        <h3 className="my-3 font-semibold">{info.title}</h3>
                        <div className="space-y-1 text-center">
                            {info.description.map((item, index) => (
                                <p key={index}>{item}</p>
                            ))}
                        </div>
                    </div>
                ))}
            </div>


        </section>
        // <h1>3</h1>
        //   <div className="test">

        //         {/* <img src={blob} alt="" /> */}

        //     </div>
    );
}

export default JoinUs;
