import React from 'react'
import './successModal.css'

function SuccessModal({ email, onClose }) {
    return (
        <div className="approved-modal modal-overlay">
            <div className="modal">
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                <h3>Successful!</h3>
                <p>The course will be sent to: {email}</p>
            </div>
        </div>
    );
}

export default SuccessModal;