import React, { useState, useEffect } from 'react';
import '../payOption/payOption.css';
import Cookies from "js-cookie";


const SubPlans = ({ email, updatePaymentStatus, selectedOption, setSelectedOption, setSelectedPrice }) => {
    const [isActive, setIsActive] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [autoClickButton, setAutoClickButton] = useState(false);
    const [discountActive, setDiscountActive] = useState(false);

    useEffect(() => {
        // Check if the discount is active by checking the stored deadline
        const storedDeadline = getDiscountDeadlineFromCookies();
        const isDiscountActive = storedDeadline > Date.now();
        setDiscountActive(isDiscountActive);

        // Log the discount status
        console.log("Discount is", isDiscountActive ? "active" : "inactive");

        // In SubPlans component
        // console.log("SubPlans component - Before checking discount:", new Date().toISOString());
        // console.log("Stored Deadline in SubPlans:", storedDeadline);
    }, []);

    const getDiscountDeadlineFromCookies = () => {
        // Retrieve the discount deadline from cookies
        return parseInt(Cookies.get("discountDeadline")) || 0;
    };

    const options = [
        {
            text: 'Community - $150',
            price: 150000,
        },
        {
            text: 'Premium Plan - $1,000',
            price: 1000000,
        },
        {
            text: 'For Life - $10,000',
            price: 10000000,
        },
    ];

    const applyDiscount = (originalPrice) => {
        // Apply a 10% discount
        return Math.round(originalPrice * 0.9);
    };

    const handleOptionClick = (option) => {
        let priceToDisplay;

        if (discountActive) {
            // If the discount is active, apply the discount to the price
            priceToDisplay = applyDiscount(option.price);
            setSelectedPrice(priceToDisplay);
        } else {
            // Use the original price
            priceToDisplay = option.price;
            setSelectedPrice(option.price);
        }

        // Update the selected option text with discount information
        const optionText = discountActive
            ? `${option.text} (-10%)`
            : option.text;

        setSelectedOption(optionText);

        // Store the selected price in localStorage
        localStorage.setItem('selectedPrice', priceToDisplay);

        // Close the dropdown by setting isActive to false
        setIsActive(false);

        // Implement the rest of your logic, e.g., showing a modal or redirecting to the payment page
    };

    return (
        <>
            <div className="form mb-10">
                <div className="form-content">
                    <h2>Subscription Plan</h2>
                    <div className={`select-menu ${isActive ? 'active' : ''}`}>
                        <div className="select-btn" onClick={() => setIsActive(!isActive)}>
                            <span className="sBtn-text">{selectedOption}</span>
                            <i className="bx bx-chevron-down"></i>
                        </div>
                        <ul className="options">
                            {options.map((option, index) => (
                                <li className="option" key={index} onClick={() => handleOptionClick(option)}>
                                    <span className="option-text">{discountActive ? `${option.text} (-10%)` : option.text}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubPlans;
