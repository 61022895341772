import React from 'react';
import './loader.css'; // Import your CSS file

function Loader() {
    return (
        <div className="loader">
            <div className="justify-content-center jimu-primary-loading"></div>
        </div>
    );
}

export default Loader;