import React, { useState, useEffect } from "react";
import './withdraw.css'
import Button from './../button/Button';
import usd from '../../assets/usd.png';
import crypt from '../../assets/cryp.png';
import '../payOption/payOption.css';
import WithdrawAmount from "../withdrawAmount/WithdrawAmount";

const Withdraw = ({ clickAction, showFirstPopup, continueAction, setWithdrawAmount, amount, error }) => {

    const options = [
        {
            text: 'Bank account',
        },
        {
            icon: crypt,
            text: 'Crypto USDT',
        },
    ];

    const [isActive, setIsActive] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Select your withdrawal method');
    const [showModal, setShowModal] = useState(false);
    const [showWithdrawPopup, setShowWithdrawPopup] = useState(false);

    const [isWithdrawDisabled, setIsWithdrawDisabled] = useState(true); // Initially disabled

    // const [amount, setAmount] = useState(""); // State to store the amount entered by the user

    // Handle changes in the amount input
    const handleAmountChange = (e) => {
        // Update the amount state when the input changes
        // setAmount(e.target.value);
    };

    // Define the condition that determines whether the button should be enabled or disabled
    const isWithdrawEnabled = true; // You can set this condition based on your logic

    const handleWithdrawClick = () => {
        // Handle the click event for the "Withdraw" button
        if (isWithdrawEnabled) {
            // Perform the withdrawal action here
            // If successful, you can disable the button again by calling setIsWithdrawDisabled(true)
            setIsWithdrawDisabled(true);
        }
    }

    // Function to handle the selection of an option
    const handleOptionClick = (optionText) => {
        setSelectedOption(optionText); // Update the selected option in the state
        localStorage.setItem('selectedWithdrawalOption', optionText); // Save selected option to localStorage
        setIsActive(false); // Close the options menu
    };

    // Function to clear the selected option from localStorage
    const clearLocalStorage = () => {
        localStorage.removeItem('selectedWithdrawalOption');
    };

    // Add an event listener for beforeunload to clear localStorage
    useEffect(() => {
        window.addEventListener('beforeunload', clearLocalStorage);

        return () => {
            // Remove the event listener when the component unmounts
            window.removeEventListener('beforeunload', clearLocalStorage);
        };
    }, []);

    useEffect(() => {
        // Load the selected option from localStorage when the component mounts
        const storedOption = localStorage.getItem('selectedWithdrawalOption');
        if (storedOption) {
            setSelectedOption(storedOption);
        }
        console.log(storedOption)
    }, []);

    const handleContinue = (e) => {
        // Update the amount state here before calling continueAction
        // setAmount(e.target.value);
        setWithdrawAmount(amount);
        continueAction();
    };

    return (
        <div className='popup withdraw-popup'>
            <i className="bx bx-arrow-back" onClick={clickAction}></i>
            <h3>Withdraw</h3>
            <div className='popup-content'>
                <div className='popup-dropdown-control'>
                    <p>Withdrawal method</p>
                    <div className={`select-menu ${isActive ? 'active' : ''}`}>
                        <div className="select-btn" onClick={() => setIsActive(!isActive)}>
                            <span className="sBtn-text">{selectedOption}</span>
                            <i className="bx bx-chevron-down"></i>
                        </div>
                        <ul className="options">
                            {options.map((option, index) => (
                                <li className="option" key={index} onClick={() => handleOptionClick(option.text)}>
                                    <span className="option-text">{option.text}</span>
                                    <img src={option.icon} alt="" />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <WithdrawAmount
                    setWithdrawAmount={setWithdrawAmount}
                    amount={amount} // Pass the amount state variable
                    error={error}
                />
                <div className='withdraw-button'>
                    <button onClick={handleContinue}> Continue</button>
                </div>
            </div>
        </div>
    );
}

export default Withdraw;
