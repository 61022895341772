import React from 'react'
import Email from '../../components/email/Email'

const Subscribe = () => {
    return (
        <>
            <Email />
        </>
    )
}

export default Subscribe
