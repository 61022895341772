import React from 'react';
import Details from '../../components/details/Details'
import Referral from './../../components/referral/Referral';


const Layout = () => {
    // console.log("Layout is rendering!");
    return(
        <>
            <Details/>
            <Referral/>
        </>
    );
}

export default Layout;