import React from 'react';
import Affiliate from '../../containers/affiliate/Affiliate'

const FinclanAffiliate = () =>{
    return(
        <Affiliate />
    );
}

export default FinclanAffiliate;
