import React, { useEffect, useState } from "react";
import PayOption from "../payOption/PayOption";
import SubPlans from "../subPlans/SubPlans";
import "./login-form.css";
import PaystackHook from './../paystack/paystack';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth/AuthContext';
import Alert from './../alert/Alert';
import Loader from './../loader/Loader';


function LoginForm({ onLoginSuccess }) {
    const options = [
        {
            text: 'Finclan Affiliate',
        },
        {
            // icon: crypt,
            text: 'Finclan Membership',
        },
    ];

    const [showSignUp, setShowSignUp] = useState(false);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isPasswordTooShort, setIsPasswordTooShort] = useState(false);
    const [signupError, setSignupError] = useState(null);
    const [loginError, setLoginError] = useState(null);

    const history = useHistory();
    const location = useLocation();
    const { login } = useAuth();
    const [referralEmail, setReferralEmail] = useState('');

    const [alertType, setAlertType] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const [showPayOtion, setShowPayOtion] = useState(false)
    const [showForm, setShowForm] = useState(true)

    const [paymentStatus, setPaymentStatus] = useState(localStorage.getItem('Payment Successful'));
    const [isLoading, setIsLoading] = useState(false);

    const [selectedOption, setSelectedOption] = useState('Select subscription');
    const [selectedPrice, setSelectedPrice] = useState(null); // Initialize selectedPrice

    useEffect(() => {
        setShowSignUp(true);
    }, []);

    function togglePasswordVisibility(event) {
        const pwFields = event.currentTarget.parentElement.parentElement.querySelectorAll(
            ".password"
        );
        pwFields.forEach((password) => {
            password.type = password.type === "password" ? "text" : "password";
        });
        event.currentTarget.classList.toggle("bx-hide");
        event.currentTarget.classList.toggle("bx-show");
    }

    function toggleForm(event) {
        event.preventDefault();
        setShowSignUp(!showSignUp);
    }

    function handleFullNameChange(event) {
        setFullName(event.target.value);
    }

    function handleEmailChange(event) {
        setEmail(event.target.value);
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
        setIsPasswordTooShort(event.target.value.length >= 1 && event.target.value.length < 8);
    }

    const isEmailValid = email.includes("@");
    const isPasswordValid = password.length >= 8;
    const isButtonDisabled = !isEmailValid || !isPasswordValid || (showSignUp && fullName === ""); // Include fullName validation

    const buttonStyle = {
        width: "100%",
        height: "100%",
        marginTop: "1.5rem",
        backgroundColor: isButtonDisabled ? "var(--bg-grey)" : "var(--button-brand)",
        cursor: isButtonDisabled ? "not-allowed" : "pointer",
        // padding: "0.9rem 1.2rem !important",
    };

    const passwordFieldStyle = {
        border: isPasswordTooShort ? "1px solid red" : " ",
    };

    const actionButtonStyle = {
        marginTop: "1.5rem",
        backgroundColor: "var(--bg-grey)",
        cursor: "not-allowed",
        width: "100%",
        // padding: "0.9rem 1.2rem !important",
    };

    // Extract referral email from URL when the component mounts
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const referralParam = searchParams.get('ref');
        if (referralParam) {
            setReferralEmail(referralParam);
        }
    }, [location.search]);

    // }

    // Define a function to update payment status and set it in localStorage
    function updatePaymentStatus(status) {
        setPaymentStatus(status);
        // console.log('status', status);
        localStorage.setItem('Payment Successful', status);

        // Call a function to handle the change immediately
        handleStorageChange({ key: 'Payment Successful', newValue: status });
    }

    // Function to handle changes in localStorage
    function handleStorageChange(event) {
        // console.log('handleStorageChange()1');

        if (event.key === 'Payment Successful' && event.newValue !== null) {
            // console.log('handleStorageChange()2');
            setShowPayOtion(false);
            setShowSignUp(false);
            setShowForm(true);
        }
    }

    useEffect(() => {
        setShowSignUp(true);

        // Add an event listener for changes to localStorage
        window.addEventListener('storage', handleStorageChange);

        return () => {
            // Remove the event listener when the component unmounts
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const [isActive, setIsActive] = useState(false);
    const [selectedMembership, setSelectedMembership] = useState('Select One');

    // Define the condition that determines whether the button should be enabled or disabled
    const isWithdrawEnabled = true; // You can set this condition based on your logic

    // Function to clear the selected option from localStorage
    const clearLocalStorage = () => {
        localStorage.removeItem('selectedMembershipOption');
    };

    // Add an event listener for beforeunload to clear localStorage
    useEffect(() => {
        window.addEventListener('beforeunload', clearLocalStorage);

        return () => {
            // Remove the event listener when the component unmounts
            window.removeEventListener('beforeunload', clearLocalStorage);
        };
    }, []);

    // Function to handle the selection of an optionz
    const handleOptionClick = (optionText) => {
        setSelectedMembership(optionText); // Update the selected option in the state
        localStorage.setItem('selectedMembershipOption', optionText); // Save selected option to localStorage
        setIsActive(false); // Close the options menu
    };


    const storedMembershipOption = localStorage.getItem('selectedMembershipOption');
    useEffect(() => {
        // Load the selected option from localStorage when the component mounts
        if (storedMembershipOption) {
            setSelectedMembership(storedMembershipOption);
        }
        // console.log(storedMembershipOption)
    }, []);

    // // Function to handle the selection of a membership option
    // const handleOptionClick = (optionText) => {
    //     setSelectedMembership(optionText); // Update the selected membership option
    //     localStorage.setItem('selectedMembershipOption', optionText);
    //     setIsActive(false); // Close the options menu
    // };


    // Function to handle signup
    async function handleSignup() {
        try {
            setIsLoading(true);

            const signupData = {
                fullName,
                email,
                password,
            };

            if (storedMembershipOption === 'Finclan Membership') {
                signupData.membership_type = 'member';
                signupData.subscription = 'community_member';
            }
            else if (storedMembershipOption === 'Finclan Affiliate') {
                signupData.membership_type = 'non_member';
                delete signupData.fullName;
                delete signupData.subscription;
            }

            if (referralEmail) {
                signupData.referrer_email = referralEmail;
            }

            console.log('data', signupData)

            const signupUrl = 'https://finclan-api-wunt.onrender.com/auth/signup';

            const response = await fetch(signupUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(signupData),
            });



            if (response.ok && storedMembershipOption === 'Finclan Membership') {
                const responseData = await response.json();

                console.log('Signup successful');
                console.log('Sent Data', signupData)
                console.log('Response data:', responseData.token);
                
                localStorage.setItem('userToken', responseData.token);
                setSignupError(null);
                setAlertType("success");
                setShowPayOtion(true)
                // setShowSignUp(false)
                setShowForm(false)
                setAlertMessage("Signup successful. Now make payment");
                setShowAlert(true);
                console.log('Succes')

                // Reset showAlert to false after displaying the alert
                setTimeout(() => {
                    setShowAlert(false);
                }, 5000);
            }
            else if (response.ok && storedMembershipOption === 'Finclan Affiliate') {
                const responseData = await response.json();
                console.log('Signup successful');
                // console.log('Sent Data', signupData)
                // console.log('Response data:', responseData.token);
                localStorage.setItem('userToken', responseData.token);
                setSignupError(null);
                setAlertType("success");
                setShowPayOtion(false)
                setShowSignUp(false);
                setShowForm(true);
                // console.log('Success')
                setAlertMessage("Signup successful. Now Login");
                setShowAlert(true);

                // Reset showAlert to false after displaying the alert
                setTimeout(() => {
                    setShowAlert(false);
                }, 5000);
            }
            else {
                // console.log('data', signupData)
                const errorData = await response.json();
                console.error('Signup error:', errorData);
                console.log('Err Msg', errorData.message)
                setSignupError(errorData.message);

                if (errorData.message === 'Email already exists') {
                    setAlertType("error");
                    setAlertMessage("Email already exists");
                    setShowAlert(true);
                } else {
                    setAlertType("error");
                    setAlertMessage("An error occurred while signing up.");
                    setShowAlert(true);
                }
            }
        } catch (error) {
            console.error('Signup error:', error);
            setSignupError('An error occurred while signing up.');
            setAlertType("error");
            setAlertMessage("An error occurred while signing up.");
            setShowAlert(true);
        } finally {
            setIsLoading(false);
            // Reset showAlert to false when signup process is complete
            setTimeout(() => {
                setShowAlert(false);
            }, 5000);
        }
    }


    // Function to handle login
    async function handleLogin() {
        try {
            setIsLoading(true);
            const loginData = {
                email,
                password,
            };

            // Replace the URL with your login API endpoint
            const loginUrl = 'https://finclan-api-wunt.onrender.com/auth/login';

            const response = await fetch(loginUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData),
            });

            if (response.ok) {
                // Login successful, fetch user's full name, email, and password
                const userData = await response.json();
                const authToken = userData.token;
                // Store the token in localStorage
                localStorage.setItem('authToken', authToken);

                const { fullName, email, password } = userData.user;

                // Store user details in localStorage
                localStorage.setItem('fullName', fullName);
                localStorage.setItem('email', email);
                localStorage.setItem('password', password);

                // Redirect to the profile page
                if (userData.user.membership_type === "non_member") {
                    // localStorage.setItem('userRole', JSON.stringify([userData.user.membership_type]));
                    localStorage.setItem('userRole', "non_member");
                    login();
                    history.push('/Dashboard');
                }
                else if (userData.user.membership_type === "member") {
                    // localStorage.setItem('userRole', JSON.stringify([userData.user.membership_type]));
                    localStorage.setItem('userRole', "member");
                    login();
                    history.push('/Profile');
                }
                // console.log('Login successful');
                login(); // Call the login function to set isLoggedIn to true
                setLoginError(null);

                // Trigger the callback to display the success alert in the parent component
                // onLoginSuccess();
            } else {
                // Handle login errors
                const errorData = await response.json();
                setLoginError(errorData.message);

                if (errorData.message === 'Invalid password') {
                    setAlertType("caution");
                    setAlertMessage("Password incorrect.");
                    setShowAlert(true);
                }

                else if (errorData.message === 'Email not found') {
                    setAlertType("caution");
                    setAlertMessage(" Email not found.");
                    setShowAlert(true);
                }

                else if (errorData.message === 'Please make payment first') {
                    setAlertType("caution");
                    setAlertMessage("Please make payment.");
                    setShowAlert(true);
                }

                else if (errorData.message === 'Payment failed') {
                    setAlertType("error");
                    setAlertMessage("Unable to make payment.");
                    setShowAlert(true);
                }

                else if (errorData.message === 'Input email first') {
                    setAlertType("caution");
                    setAlertMessage("Input email first.");
                    setShowAlert(true);
                }

                else {
                    setAlertType("error");
                    setAlertMessage("An error occurred while logging in.");
                    setShowAlert(true);
                }
            }
        } catch (error) {
            console.error('Login error:', error);
            setLoginError('An error occurred while logging in.');
            setAlertType("error");
            setAlertMessage("An error occurred while logging in.");
            setShowAlert(true);
        } finally {
            setIsLoading(false);
            // Reset showAlert to false when login process is complete
            setTimeout(() => {
                setShowAlert(false);
            }, 5000);
        }
    }


    return (
        <section className="container__ forms">
            {showForm && (
                <div className={`form ${showSignUp ? "signup" : "login"}`}>
                    <div className="form-content">
                        <h2>{showSignUp ? "Sign up to Finclan." : "Login to Finclan."}</h2>
                        <form action="/profile">
                            {showSignUp && (
                                <>
                                    <div className="input-container">
                                        <p className="label_ pl-2">Full Name</p>
                                        <div className="field input-field">
                                            <input
                                                type="text"
                                                placeholder="Enter your full name"
                                                className="input"
                                                value={fullName}
                                                onChange={handleFullNameChange}
                                                autoComplete="username"
                                            />
                                        </div>
                                    </div>

                                    <div className="input-container input-row">
                                        <div className="input-container-col">
                                            <p className="label_ pl-2">Email</p>
                                            <div className="field input-field">
                                                <input
                                                    type="email"
                                                    placeholder="Enter your email"
                                                    className="input"
                                                    value={email}
                                                    onChange={handleEmailChange}
                                                    autoComplete="email"
                                                />
                                            </div>
                                        </div>
                                        <div className="input-container-col">
                                            <p className="label_ pl-2">Password</p>
                                            <div
                                                className={`field input-field`} style={passwordFieldStyle}
                                            >
                                                <input
                                                    type="password"
                                                    placeholder="*************"
                                                    className={`password`}
                                                    value={password}
                                                    onChange={handlePasswordChange}
                                                    autoComplete="current-password"
                                                />
                                                <i
                                                    className="bx bx-hide eye-icon"
                                                    onClick={togglePasswordVisibility}
                                                ></i>
                                            </div>
                                            {isPasswordTooShort && password.length > 0 && (
                                                <div className="error-message">
                                                    <p>Password must be at least 8 characters</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="input-container" style={{ "height": "75px" }}>
                                        <p className="label_ pl-2" style={{ "marginBottom": "-0.5rem" }}>Membership Type</p>
                                        <div className={`select-menu ${isActive ? 'active' : ''}`}>
                                            <div className="select-btn" onClick={() => setIsActive(!isActive)}>
                                                <span className="sBtn-text">{selectedMembership}</span>
                                                <i className="bx bx-chevron-down"></i>
                                            </div>
                                            <ul className="options">
                                                {options.map((option, index) => (
                                                    <li className="option" key={index} onClick={() => handleOptionClick(option.text)}>
                                                        <span className="option-text">{option.text}</span>
                                                        <img src={option.icon} alt="" />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            )}

                            {showSignUp ? null : (
                                <>
                                    <div className="input-container">
                                        <p className="label_ pl-2">Email</p>
                                        <div className="field input-field">
                                            <input
                                                type="email"
                                                placeholder="Enter your email"
                                                className="input"
                                                value={email}
                                                onChange={handleEmailChange}
                                                autoComplete="email"
                                            />
                                        </div>
                                    </div>

                                    <div className="input-container">
                                        <p className="label_ pl-2">Password</p>
                                        <div
                                            className={`field input-field`} style={passwordFieldStyle}
                                        >
                                            <input
                                                type="password"
                                                placeholder="*************"
                                                className={`password`}
                                                value={password}
                                                onChange={handlePasswordChange}
                                                autoComplete="current-password"
                                            />
                                            <i
                                                className="bx bx-hide eye-icon"
                                                onClick={togglePasswordVisibility}
                                            ></i>
                                        </div>
                                        {isPasswordTooShort && password.length > 0 && (
                                            <div className="error-message">
                                                <p>Password must be at least 8 characters</p>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}


                            <div className="form-link">
                                {isLoading ? (
                                    <button
                                        type="button"
                                        style={actionButtonStyle}
                                    >
                                        <Loader />
                                    </button>
                                ) : (

                                    <button
                                        type="button"
                                        style={buttonStyle}
                                        disabled={isButtonDisabled}
                                        onClick={showSignUp ? handleSignup : handleLogin}
                                    >
                                        {showSignUp ? "Proceed" : "Login"}
                                    </button>
                                )}
                            </div>
                        </form>

                        <div className="form-link">
                            <span>
                                {showSignUp ? "Already have an account? " : "If you do not have an account, "}
                                <a href="#/" className="link signup-link" onClick={toggleForm}>
                                    {showSignUp ? "Login" : "purchase"}
                                </a>
                                {showSignUp ? "" : " a finclan community plan "}
                            </span>
                            <br />
                            <a href="/Reset-Password">Forgot Password?</a>
                        </div>

                        {signupError && (
                            <div className="error-message">
                                <p>{signupError}</p>
                            </div>
                        )}

                        {loginError && (
                            <div className="error-message">
                                <p>{loginError}</p>
                            </div>
                        )}


                        {showAlert && <Alert type={alertType} message={alertMessage} />}
                    </div>

                </div>
            )}

            {showSignUp && showPayOtion &&
                <>
                    <SubPlans
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        setSelectedPrice={setSelectedPrice}
                    />
                    <PayOption
                        email={email}
                        updatePaymentStatus={updatePaymentStatus}
                        selectedOption={selectedOption}
                        selectedPrice={selectedPrice}
                    />
                </>
            }
        </section>
    );
}

export default LoginForm;