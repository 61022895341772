import React from 'react'
import imgEmail from '../../assets/imgEmail.png';
import './email.css'
import Button from '../button/Button';

const Email = () => {
    return (
        <div className="email-overlay modal-overlay">
            <h3>E-mail Subscription</h3>
            <div className="modal">
                <img src={imgEmail} alt="" />
                <p>You are now subscribed to our mails. Our newsletters, announcements, new products, services, awesome offers and discounts will get to you first before anyone else!</p>
                <Button link="./" text={"Back Home"}/>
            </div>
        </div>
    )
}

export default Email;

