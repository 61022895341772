import React from 'react'
import UsdtPayment from '../../components/usdtPayment/UsdtPayment'

const UsdtPay = () => {
    return (
        <>
            <UsdtPayment/>
        </>
    )
}

export default UsdtPay;
