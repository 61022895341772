import React from 'react'
import LoginForm from '../../components/form/LoginForm'

const Login = () => {
    return (
        <>
            <LoginForm/>
        </>
    )
}

export default Login
