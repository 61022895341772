import React, { useState, useEffect } from "react"
import './withdrawDetails.css'
import { SearchBar } from './../bankResult/SearchBar';
import BankResult from "../bankResult/BankResult";
import WithdrawAmount from './../withdrawAmount/WithdrawAmount';
import ApiDetails from './../bankDetails/ApiDetails'
import AcctName from './../acctName/AcctName';

const WithdrawDetails = ({ clickAction, showSecondPopup, amount }) => {

    const [isActive, setIsActive] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Select your withdrawal method');
    const [showModal, setShowModal] = useState(false);
    const [showWithdrawPopup, setShowWithdrawPopup] = useState(false);

    const [isWithdrawDisabled, setIsWithdrawDisabled] = useState(true); // Initially disabled
    const [results, setResults] = useState([]);
    const [accountNumber, setAccountNumber] = useState("");
    const [userName, setUserName] = useState('');

    const [input, setInput] = useState("");

    const [withdrawSuccess, setWithdrawSuccess] = useState(false);

    const handleWithdrawClick = () => {
        // Define the JSON data to be sent in the POST request
        const acctNNum = localStorage.getItem("accountNumber")
        const acctName = localStorage.getItem("accountName")
        const bankCode = localStorage.getItem("selectedBankCode")
        const authtoken = localStorage.getItem("authToken")

        const postData = {
            token: authtoken,
            account_number: acctNNum,
            account_name: acctName,
            bank_code: bankCode,
            currency: "NGN",
            amount: parseFloat(amount), // Convert amount to a number
        };

        // Send the POST request to the API
        fetch("https://finclan-api.onrender.com/payment/withdraw", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
        })
        .then((response) => {
            if (response.ok) {
                // Withdrawal was successful
                setWithdrawSuccess(true);
                // Handle success as needed
                console.log("Withdrawal successful");
            } else {
                // Withdrawal failed
                setWithdrawSuccess(false);
                // Handle the error as needed
                console.error("Withdrawal failed");
            }
        })
        .catch((error) => {
            // Handle network or other errors
            console.error("Error:", error);
        });

        // Additional logging
        console.log("Withdrawal request sent:", postData);
    };

    const fetchData = (value) => {
        fetch("https://finclan-api-wunt.onrender.com/payment/bank_list")
            .then((response) => response.json())
            .then((json) => {
                const results = json.banks.data.filter((bank) => {
                    return (
                        value &&
                        bank &&
                        bank.name &&
                        bank.name.toLowerCase().includes(value.toLowerCase())
                    );
                });
                setResults(results);
            });
    };

    const handleChange = (value) => {
        setInput(value);
        fetchData(value);
    };

    // Define the condition that determines whether the button should be enabled or disabled
    const isWithdrawEnabled = true; // You can set this condition based on your logic

    // const handleWithdrawClick = () => {
    //     // Handle the click event for the "Withdraw" button
    //     if (isWithdrawEnabled) {
    //         // Perform the withdrawal action here
    //         // If successful, you can disable the button again by calling setIsWithdrawDisabled(true)
    //         setIsWithdrawDisabled(true);
    //     }
    // }
    // const closePopup = () => {
    //     setShowWithdrawPopup(!showWithdrawPopup);
    // };

    // const notClosePopup = (e) => {
    //     e.stopPropagation();
    // };




    // Function to handle the selection of an option
    const handleOptionClick = (optionText) => {
        setSelectedOption(optionText); // Update the selected option in the state
        localStorage.setItem('selectedWithdrawalOption', optionText); // Save selected option to localStorage
        setIsActive(false); // Close the options menu
    };




    // Function to handle changes in the account number input
    const handleAccountNumberChange = (e) => {
        const value = e.target.value;
        setAccountNumber(value);
        // Save the account number to localStorage whenever it changes
        localStorage.setItem('accountNumber', value);
    };

    useEffect(() => {
        const savedAccountNumber = localStorage.getItem('accountNumber');
        if (savedAccountNumber) {
            setAccountNumber(savedAccountNumber);
        }
    }, []);

    // Retrieve the selected bank code from localStorage
    const selectedBankCode = localStorage.getItem('selectedBankCode')

    const handleAPIResponse = (name) => {
        setUserName(name);
    };

    // Function to clear relevant items from localStorage
    const clearLocalStorage = () => {
        localStorage.removeItem('selectedWithdrawalOption');
        localStorage.removeItem('accountNumber'); // Clear the accountNumber
        localStorage.removeItem('accountName')
        localStorage.removeItem('selectedBankCode');
        localStorage.removeItem('bankName');
        // Add other relevant items you want to clear here
    };

    // Add an event listener for beforeunload to clear localStorage
    useEffect(() => {
        window.addEventListener('beforeunload', clearLocalStorage);

        return () => {
            // Remove the event listener when the component unmounts
            window.removeEventListener('beforeunload', clearLocalStorage);
        };
    }, []);

    return (
        <>

            <div className='popup withdraw-popup'>
                <i className="bx bx-arrow-back" onClick={clickAction}></i>
                <h3>Withdraw</h3>
                <div className='popup-content withdraw-popup-content'>
                    <div className='input-label'>
                        <p>Account number</p>
                        <input
                            type="text"
                            placeholder='Enter Account Number'
                            value={accountNumber}
                            onChange={handleAccountNumberChange} // Call the function on input change
                        />
                    </div>
                    <div className='input-wrapper input-label'>
                        <p>Bank name</p>
                        <BankResult results={results} setResults={setResults} />
                    </div>
                    <ApiDetails accountNumber={accountNumber} bankCode={selectedBankCode} onAPIResponse={handleAPIResponse} />
                    {/* <AcctName userName={userName} /> */}
                    {/* <WithdrawAmount /> */}
                    <p>Amount: ₦{amount}</p> {/* Display the amount */}
                    <div className='withdraw-button'>
                        <button onClick={handleWithdrawClick}>Withdraw</button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default WithdrawDetails;