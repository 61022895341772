import React, { useState, useEffect } from "react";
import discount from "../../assets/discount.png";
import "./discount.css";
import Button from "../../components/button/Button";
import { FaDiscord } from "react-icons/fa";
import Cookies from "js-cookie";

const Discount = () => {
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [milliseconds, setMilliseconds] = useState(0);

    const setDeadlineCookie = (deadline, remainingTime) => {
        // Set a cookie with the deadline timestamp and remaining time
        Cookies.set("discountDeadline", deadline.getTime());
        Cookies.set("discountRemainingTime", remainingTime);
    };

    const getDeadlineCookie = () => {
        // Retrieve the deadline timestamp and remaining time from the cookie
        return {
            deadline: parseInt(Cookies.get("discountDeadline")) || 0,
            remainingTime: parseInt(Cookies.get("discountRemainingTime")) || 0,
        };
    };

    function getTimeRemaining(endtime) {
        const t = endtime - Date.now();
        const milliseconds = Math.floor(t % 1000);
        const seconds = Math.floor((t / 1000) % 60);
        const minutes = Math.floor((t / 1000 / 60) % 60);
        const hours = Math.floor((t / (1000 * 60 * 60)) % 24);

        return {
            total: t,
            hours,
            minutes,
            seconds,
            milliseconds,
        };
    }

    useEffect(() => {
        // Check if there's a stored deadline
        const { deadline, remainingTime } = getDeadlineCookie();

        if (deadline > Date.now()) {
            console.log("Discount is active.");

            // If there's a stored deadline and it's in the future, resume the countdown
            const interval = setInterval(() => {
                const t = getTimeRemaining(deadline + remainingTime);
                setHours(('00' + t.hours).slice(-2));

                setMinutes(("00" + t.minutes).slice(-2));
                setSeconds(("00" + t.seconds).slice(-2));
                setMilliseconds(("00" + t.milliseconds).slice(-2));

                if (t.total <= 0) {
                    // If the timer has reached zero, stop the countdown
                    clearInterval(interval);
                    setHours("00")
                    setMinutes("00");
                    setSeconds("00");
                    setMilliseconds("00");

                    // Set a new deadline for 1 hour later
                    const newDeadline = new Date(Date.now() + 20 * 60 * 1000); // 1 hour
                    setDeadlineCookie(newDeadline, 0);

                    console.log("Discount is now inactive. New discount will start in 1 hour.");

                    // Wait for 1 hour before starting the discount again
                    setTimeout(() => {
                        const newDeadline = new Date(Date.now() + 20 * 60 * 1000); // 20 minutes
                        setDeadlineCookie(newDeadline, 0);
                        console.log("Discount is now active again.");
                    }, 60 * 60 * 1000);
                }
            }, 1);

            return () => clearInterval(interval);
        } else {
            // If there's no stored deadline or it's in the past, set a new deadline
            const newDeadline = new Date(Date.now() + 20 * 60 * 1000); // 20 minutes
            setDeadlineCookie(newDeadline, 0);
            console.log("Discount is inactive.");
        }
    }, []);

    return (
        <div className="discount">
            <img src={discount} alt="" />

            <div className="discount-info px-20">
                <h1>
                    10% off <br /> if you pay within
                </h1>
                <div id="clockdiv">
                    <div>
                        <span className="hours">{hours}</span>
                        <h2>:</h2>
                    </div>
                    <div>
                        <span className="minutes">{minutes}</span>
                        <h2>:</h2>
                    </div>
                    <div>
                        <span className="seconds">{seconds}</span>
                        {/* <h2>:</h2> */}
                    </div>
                    {/* <div>
                        <span className="milliseconds">{milliseconds}</span>
                    </div> */}
                    <br />
                </div>
                <p>
                    Once the timer is up, the discount is over.<br /> so Hurry Now!
                </p>
                <Button
                    link="https://bit.ly/finclan-discord"
                    text="Join our discord group"
                    icon={<FaDiscord />}
                    style={{ backgroundColor: "#5865f2" }}
                />
            </div>
        </div>
    );
};

export default Discount;
