import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({ link, text, icon, style, className, myClass, clickAction }) => {
    const handleLinkClick = (e) => {
        if (link.startsWith('http')) {
            // If the link is an external URL (starts with http), open it in a new tab
            window.open(link, '_blank');
            e.preventDefault(); // Prevent the default link behavior
        } else {
            window.scrollTo({
                top: 0,
                left: 0,
            });
        }
    };

    return (
        <Link to={link} className={myClass} onClick={handleLinkClick}>
            <button className={className} style={style} onClick={clickAction}>
                {text} {icon}
            </button>
        </Link>
    );
}

export default Button;
