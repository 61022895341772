
import React from "react";
import { Link } from 'react-router-dom';
import logo2 from '../../assets/logo2.png';
import './footer.css'
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

const socialIcons = [
    { Icon: AiFillInstagram, href: "https://www.instagram.com/finclancapital" },
    { Icon: FaLinkedin, href: "https://www.linkedin.com/company/finclancapital" },
    { Icon: FaTwitter, href: "https://www.twitter.com/finclancapital" }
];

const links = [
    { text: 'Home', path: '/' },
    { text: 'Courses', path: '/course' },
    { text: 'Mentorship', path: '/mentorship' }
];

const handleLinkClick = () => {
    window.scrollTo({
        top: 0,
        left: 0,
    });
};

function Footer() {
    return (
        <footer>
            <div className="footer-container py-14 px-16 mx-0 space-y-6 divide-y divide-gray-400 md:space-y-12 divide-opacity-50">
                <div className="flex flex-col gap-12">
                    <div className="pb-6 col-span-full md:pb-0 md:col-span-6">
                        <img src={logo2} alt="" width={"150"} />

                    </div>
                    <div className="footer-text flex gap-72 md:text-left items-end">
                        <div>
                            <p>Get product updates, launch info, and special deals by signing up for our newsletter.</p>
                            <form action="https://finclancapital.us14.list-manage.com/subscribe/post?u=230c9156bebe1d2c8046ee705&amp;id=8de6f78bd8&amp;f_id=00d5a7e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="footer-input flex items-center space-x-4 mt-3" target="_blank">

                                <input
                                    className="border-0 rounded py-2 px-4 focus:outline-none"
                                    type="email"
                                    name="EMAIL"
                                    placeholder="Enter your email"
                                    required=""
                                />
                                <input
                                    className="bg-indigo-500 text-white rounded py-2 px-4 focus:outline-none"
                                    type="submit"
                                    name="subscribe"
                                    value="SignUp"
                                />


                            </form>
                        </div>
                        <div>
                            <p className="pb-5">Follow us on social media</p>
                            <ul className="footer-icons-container flex space-x-4 gap-5">
                                {socialIcons.map((socialIcon, index) => (
                                    <li key={index}>
                                        <a
                                            rel="noopener noreferrer"
                                            href={socialIcon.href}
                                            className="footer-icons"
                                        >
                                            <socialIcon.Icon />
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="footer-links-container flex flex-col pt-9 gap-8">
                    <div className="footer-links flex gap-14">
                        {links.map((link) => (
                            <Link
                                key={link.path}
                                to={link.path}
                                className=""
                                onClick={handleLinkClick}

                            >
                                {link.text}
                            </Link>
                        ))}
                    </div>
                    <div className="copyright pt-4">
                        <span>©2024 All rights reserved</span>

                    </div>
                </div>
            </div>

            <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
            <script type="text/javascript">
                {` (function($) {
            window.fnames = new Array();
            window.ftypes = new Array();
            fnames[0]='EMAIL';
            ftypes[0]='email';
            fnames[1]='FNAME';
            ftypes[1]='text';
            fnames[2]='LNAME';
            ftypes[2]='text';
            fnames[3]='ADDRESS';
            ftypes[3]='address';
            fnames[4]='PHONE';
            ftypes[4]='phone';
            fnames[5]='BIRTHDAY';
            ftypes[5]='birthday';
          }(jQuery));
          var $mcj = jQuery.noConflict(true);`}
            </script>

        </footer>

    )
}
export default Footer;
