import React from 'react';
import './withdrawAmount.css';

const WithdrawAmount = ({ setWithdrawAmount, amount, error }) => {
    const handleAmountChange = (e) => {
        // Update the amount state and pass it to the parent component
        const newAmount = e.target.value;
        setWithdrawAmount(newAmount); // Use setWithdrawAmount to update the amount
    };

    return (
        <div className='popup-amount'>
            <div>
                <input
                    type="text"
                    placeholder='Amount'
                    value={amount}
                    onChange={handleAmountChange}
                />
            </div>
            {error && <b className='error-message'>{error}</b>}
            <p className='amount-p'>₦0 - ₦100</p>
        </div>
    )
}

export default WithdrawAmount;
