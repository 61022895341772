import React from "react";
import './faq.css'
import { FaAngleRight } from "react-icons/fa";

const faqData = [
    {
        question: "What is this clan really all about?",
        answer:
            "Finclan is a community of members building finance of the desired future."
    },
{
        question: "How to get started with Finclan?",
        answer:
            "A click/tap on the join button below does the magic and viola greatness!"
    },
    {
        question: "What are the benefits from joining?",
        answer:
            "More than what we say here are what you get on being part of their clan."
    }
];

function Faq() {
    return (
        <section className="">
            <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
                <h2 className="mt-6 mb-2 text-4xl font-bold leading-none text-center sm:text-5xl">
                    FAQs
                </h2>
                <div className="faq-container flex flex-col justify-center divide-y sm:px-8 lg:px-12 xl:px-32 divide-gray-200">
                    {faqData.map((item, index) => (
                        <details open="" key={index} className="text-center">
                            <summary className="outline-none cursor-pointer">

                                <h3 className="big">
                                    {item.question}
                                </h3>
                                <FaAngleRight className="faq-icon"/>
                            </summary>
                            <div className="pr-4 pb-4">
                                <p>{item.answer}</p>
                            </div>
                        </details>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Faq;