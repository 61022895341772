import React, { useState } from 'react';
import './newPassword.css';
import Button from './../button/Button';

const NewPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [reenteredPassword, setReenteredPassword] = useState('');

    // Function to handle password visibility
    function togglePasswordVisibility(event) {
        const pwFields = event.currentTarget.parentElement.parentElement.querySelectorAll(
            ".password"
        );
        pwFields.forEach((password) => {
            password.type = password.type === "password" ? "text" : "password";
        });
        event.currentTarget.classList.toggle("bx-hide");
        event.currentTarget.classList.toggle("bx-show");
    }

    // Function to send the new password and email to the API
    const sendPasswordToAPI = () => {
        const passEmail = localStorage.getItem('passEmail');

        if (!newPassword || newPassword !== reenteredPassword) {
            alert("Passwords do not match or are empty");
            return;
        }

        const data = {
            email: passEmail,
            password: newPassword,
        };

        // Replace 'your_api_endpoint' with your actual API endpoint
        fetch('https://finclan-api-wunt.onrender.com/user/reset_password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.ok) {
                    // Password reset successful, you can navigate to a new page here
                    window.location.href = '/signup'; // Replace with your desired success page URL
                } else {
                    alert('Failed to reset password. Please try again.');
                }
            })
            .catch((error) => {
                alert('An error occurred while resetting the password.');
            });
    };

    return (
        <div className="affiliate-conatiner">
            <div className="password-box">
                <h2>Reset Password.</h2>
                <div className="input-container">
                    <p className="label_ pl-2">New Password</p>
                    <div className="field input-field">
                        <input
                            type="password"
                            placeholder="*************"
                            className={`password`}
                            autoComplete="new-password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <i
                            className="bx bx-hide eye-icon"
                            onClick={togglePasswordVisibility}
                        ></i>
                    </div>
                </div>

                <div className="input-container">
                    <p className="label_ pl-2">Re-enter new password</p>
                    <div className="field input-field">
                        <input
                            type="password"
                            placeholder="*************"
                            className={`password`}
                            autoComplete="new-password"
                            value={reenteredPassword}
                            onChange={(e) => setReenteredPassword(e.target.value)}
                        />
                    </div>
                </div>

                <input className='pass-submit' type='submit' onClick={sendPasswordToAPI} />
            </div>
        </div>
    );
};

export default NewPassword;
